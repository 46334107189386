import {CellButton, Group, Panel, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import * as React from "react";
import {Icon28AddOutline} from "@vkontakte/icons";
import {AdminBoostersList} from "../../../components/booster/AdminBoostersList";
import {useParams} from "@vkontakte/vk-mini-apps-router";

export const AdminBoosterListPanel = ({routeNavigator, setPopout, clearPopout, openError}) => {

    const params = useParams();

    return (
        <Panel id={'adminBoosterListPanel'}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Бустеры
            </PanelHeader>
            <Group>
                <CellButton onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/boosters/create')} before={<Icon28AddOutline/>}>
                    Добавить бустер
                </CellButton>
            </Group>
            <AdminBoostersList addLink={false} showAllLink={false} />
        </Panel>
    )
}