import {Panel, PanelHeader} from "@vkontakte/vkui";
import * as React from "react";
import {VkUser} from "../../components/VkUser";
import {useEffect, useState} from "react";
import {UserCard} from "../../components/user/UserCard";
import {UserAnalytic} from "../../components/user/UserAnalytic";
import UserService from "../../services/user/UserService";

export const ProfilePanel = ({...props}) => {
    const [analyticData, setAnalyticData] = useState(null);
    const userService = new UserService(props.setPopout);

    const fetchPanelData = async () => {
        await userService.getUserAnalytic().then(res => {
            setAnalyticData(res);
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <Panel>
            <PanelHeader after={<VkUser user={props.user} mode={'plain'} />}>
                Профиль
            </PanelHeader>
            <UserCard user={props.user} subscription={props.subscription} />
            <UserAnalytic data={analyticData} />
        </Panel>
    )
}
