import {CustomModal} from "../../components/vkUI/CustomModal";
import * as React from "react";
import {Group} from "@vkontakte/vkui";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import ActionService from "../../services/action/ActionService";
import {VkSubscribeActionForm} from "../../components/vk/VkSubscribeActionForm";
import {VkSendReviewActionForm} from "../../components/vk/VkSendReviewActionForm";
import {VkSetLikeOnLastPostsActionForm} from "../../components/vk/VkSetLikeOnLastPostsActionForm";
import {VkSetLikeActionForm} from "../../components/vk/VkSetLikeActionForm";
import {VkSendCommentActionForm} from "../../components/vk/VkSendCommentActionForm";
import {VkRepostActionForm} from "../../components/vk/VkRepostActionForm";
import {VkActionDataContent} from "../../components/vk/VkActionDataContent";
import {VkGroupCard} from "../../components/vk/VkGroupCard";
import {VkDefaultActionForm} from "../../components/vk/VkDefaultActionForm";
import store from "../../store";
import {updateVkGroupActionsSliceInStore} from "../../features/actions/vkGroupActionsSlice";
import {updateVkGroupScoresInStore} from "../../features/vkGroupScores/vkGroupScoresSlice";

export const VkActionModal = ({id, setPopout}) => {
    const actionService = new ActionService(setPopout)
    const params = useParams();
    const [action, setAction] = React.useState(null);

    const fetchData = async () => {
        await actionService.show(params.uuid).then(res => {
            setAction(actionService.model);
        });
    }

    const setActionIsCompleted = () => {
        action.is_completed = true;
        store.dispatch(updateVkGroupActionsSliceInStore(action));
        store.dispatch(updateVkGroupScoresInStore(action.amount));
    }

    const getActionForm = () => {
        if (action) {
            switch (action.action_type_id) {
                case 1:
                    return <VkSubscribeActionForm setActionIsCompleted={setActionIsCompleted} />
                case 2:
                    return <VkSendReviewActionForm setActionIsCompleted={setActionIsCompleted} />
                case 3:
                    return <VkSetLikeOnLastPostsActionForm setActionIsCompleted={setActionIsCompleted} />
                case 4:
                    return <VkSetLikeActionForm setActionIsCompleted={setActionIsCompleted} />
                case 5:
                    return <VkSendCommentActionForm setActionIsCompleted={setActionIsCompleted} />
                case 6:
                    return <VkRepostActionForm setActionIsCompleted={setActionIsCompleted} />
                default:
                    return <VkDefaultActionForm setActionIsCompleted={setActionIsCompleted} />
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <CustomModal id={id} headerText={action ? action.name : null}>
            {
                action && <Group>
                    <VkGroupCard vkGroup={action.vkGroup} />
                    <VkActionDataContent actionData={action.data} />
                    {getActionForm()}
                </Group>
            }
        </CustomModal>
    )
}