import {Avatar, Button, ButtonGroup, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Delete, Icon12Pencil, Icon56UsersOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import store from "../../store";
import {useSelector} from "react-redux";
import BoosterService from "../../services/booster/BoosterService";
import {deleteAdminBoosterInStore, setAdminBoostersInStore} from "../../features/boosters/adminBoostersSlice";
import {DeleteButton} from "../buttons/DeleteButton";

export const AdminBoostersList = ({showAllLink, groupId, addLink, limit}) => {
    const boosterService = new BoosterService();

    const [fetching, setFetching] = React.useState(true);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const boosters = useSelector(state => state.adminBoosters.list);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        await boosterService.all({limit: limit, vk_group_id: groupId}).then(res => {
            store.dispatch(setAdminBoostersInStore(boosterService.list));
            setFetching(false);
        });
    }

    const onClickDeleteBooster = async (booster) => {
        await boosterService.delete(booster.uuid).then(res => {
            store.dispatch(deleteAdminBoosterInStore(booster.id));
            setFetching(false);
        });
        setDeleteLoading(true)
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Бустеры'} description={'Бустеры это наборы действия за которые клиент может сразу получить подарок.'} showAllLink={showAllLink ? '/admin/groups/' + groupId + '/boosters' : null} addLink={addLink ? '/admin/groups/' + groupId + '/boosters/create' : null} actions={null}>
            {
                !fetching ?
                    boosters.length ?
                        boosters.map((booster, index) => (
                            <GroupContentList
                                key={booster.id}
                                before={<Avatar size={48} src={booster.vkGroup ? booster.vkGroup.photo : null} />}
                                content={booster.name}
                                text={booster.vkGroup ? booster.vkGroup.name : null}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12Pencil/>} onClick={() => routeNavigator.push('/admin/groups/' + groupId + '/boosters/update/' + booster.uuid)} mode="primary"/>
                                        <DeleteButton loading={deleteLoading} onClick={() => onClickDeleteBooster(booster)} />
                                    </ButtonGroup>
                                }
                            />
                        )) :  <Placeholder icon={<Icon56UsersOutline />}>
                            Пока еще нет Бустеров
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}