import {Avatar, Footer, Group, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Separator, Spinner} from "@vkontakte/vkui";
import {VkUser} from "../../components/VkUser";
import * as React from "react";
import {showClientGroup} from "../../services/vk/VkClientGroupService";
import {useEffect} from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {Icon16StarCircleFillYellow} from "@vkontakte/icons";
import {CustomPanel} from "../../components/vkUI/CustomPanel";
import {numToWord} from "../../helpers/StrHelper";
import {Form} from "../../components/form/Form";
import {FormField} from "../../components/form/FormField";
import ReviewService from "../../services/review/ReviewService";
import RatingSelector from "../../components/rating/RatingSelector";

export const VkGroupShowReviewsPanel = ({...props}) => {
    const reviewService = new ReviewService(props.setPopout);
    const params = useParams();

    const [group, setGroup] = React.useState(null);
    const [fetching, setFetching] = React.useState(true);
    const [formData, setFormData] = React.useState({
        vk_group_id: params.groupId
    });
    const [loading, setLoading] = React.useState(false);

    const handler = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleRatingChange = (rating) => {
        formData.rating = rating;
    };

    const onSubmit = async () => {
        setLoading(true);
        await reviewService.create(formData).then(res => {
            fetchPanelData();
            setLoading(false);
        }).catch((exception) => {
            console.log(exception)
            setLoading(false);
        });
    }

    const fetchPanelData = async () => {
        await showClientGroup(params.groupId).then((response) => {
            setGroup(response)
            setFetching(false)
        })
    }

    useEffect(() => {
        fetchPanelData();
    }, [])

    return (
        <CustomPanel id={props.id}>
            {
                !fetching ?
                    <>
                        <PanelHeader
                            delimiter="spacing"
                            before={<PanelHeaderBack onClick={() => props.routeNavigator.back()}/>}
                            after={<VkUser user={props.user} mode={'plain'}/>}
                        >
                            Отзывы
                        </PanelHeader>
                        <Group>
                            <Placeholder
                                style={{padding: 10}}
                                icon={
                                    <Avatar src={group.photo} size={96} />
                                }
                                header={
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {group.name} <span style={{display: 'flex', alignItems: 'center', paddingLeft: 5}}><Icon16StarCircleFillYellow style={{paddingRight: 2}}/>{group.rating}</span>
                                    </div>
                                }
                            >
                                {group.description}
                            </Placeholder>
                            <Group mode={'plain'}>
                                <Form loading={loading} onSubmit={onSubmit} submitButtonText={'Отправить'}>
                                    <FormField
                                        title={'Отзыв'}
                                        type={'textarea'}
                                        name={'review'}
                                        value={formData.review}
                                        setValue={handler}
                                    />
                                    <RatingSelector onRatingChange={handleRatingChange} />
                                </Form>
                            </Group>
                            {
                                group.reviews.length ?
                                    group.reviews.map((review, i) => (
                                        <>
                                            <Separator key={i} />
                                            <RichCell
                                                key={i}
                                                before={<Avatar size={72} src={review.vkUser.photo} />}
                                                text={review.review}
                                                after={
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        {Array.from({ length: review.rating }).map((_, index) => (
                                                            <Icon16StarCircleFillYellow key={index} style={{margin: '0 2px'}}/>
                                                        ))}
                                                    </div>
                                                }
                                                afterCaption={review.created_at}
                                                multiline
                                            >
                                                {review.vkUser.name}
                                            </RichCell>
                                        </>
                                    )) : <Placeholder header={'Еще нет отзывов'}>
                                        Оставьте первый отзыв в этом сообществе
                                    </Placeholder>
                            }
                        </Group>
                        <Footer Component="div">{group.reviews.length} {numToWord(group.reviews.length, ['отзыв', 'отзыва', 'отзывов'])}</Footer>
                    </>
                    : <Spinner size={'medium'}/>
            }
        </CustomPanel>
    )
}