import * as React from "react";
import {Layout} from "./components/layout/Layout";
import {useEffect, useState} from "react";
import {ModalRoot, useAdaptivityConditionalRender, useAdaptivityWithJSMediaQueries, usePlatform} from "@vkontakte/vkui";
import {UserUpdateModal} from "./modals/user/UserUpdateModal";
import {GiftShowQrCodeModal} from "./modals/gift/GiftShowQrCodeModal";
import {GiftUserSelectModal} from "./modals/gift/GiftUserSelectModal";
import {Icon28GiftOutline, Icon28UserCircleOutline, Icon28Users3Outline} from "@vkontakte/icons";
import {DesktopLayout} from "./components/layout/desktop/DesktopLayout";
import {MobileLayout} from "./components/layout/desktop/MobileLayout";
import {BoosterUpdateModal} from "./modals/booster/BoosterUpdateModal";
import {BoosterCreateModal} from "./modals/booster/BoosterCreateModal";
import {ActionCreateModal} from "./modals/action/ActionCreateModal";
import {GiftCreateModal} from "./modals/gift/GiftCreateModal";
import {useActiveVkuiLocation, useGetPanelForView, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {BoosterQrCodeModal} from "./modals/booster/BoosterQrCodeModal";
import {ClientShowModal} from "./modals/clients/ClientShowModal";
import {ClientShowGiftsModal} from "./modals/clients/ClientShowGiftsModal";
import {ClientShowBoostersModal} from "./modals/clients/ClientShowBoostersModal";
import {EnterGiftCodeModal} from "./modals/gift/EnterGiftCodeModal";
import {BuyGiftModal} from "./modals/gift/BuyGiftModal";
import {ActionUpdateModal} from "./modals/action/ActionUpdateModal";
import {GiftUpdateModal} from "./modals/gift/GiftUpdateModal";
import {VkActionModal} from "./modals/vk/VkActionModal";
import VkService from "./services/vk/VkService";
import {UserAdminSettingsModal} from "./modals/user/UserAdminSettingsModal";
import {VkGroupsFilerModal} from "./modals/vk/VkGroupsFilerModal";
import {GiftCodeCreateModal} from "./modals/gift/GiftCodeCreateModal";
import {VkGroupUpdateModal} from "./modals/vk/VkGroupUpdateModal";
import {LoyaltyLevelCreateModal} from "./modals/loyaltyLevel/LoyaltyLevelCreateModal";
import {LoyaltyLevelUpdateModal} from "./modals/loyaltyLevel/LoyaltyLevelUpdateModal";
import {ListFilterModal} from "./modals/ListFilterModal";
import {VkUserScoresModal} from "./modals/user/VkUserScoresModal";
import {ReferalSystemModal} from "./modals/referalSystem/ReferalSystemModal";
import {WelcomeFaqModalCard} from "./modals/faq/client/WelcomeFaqModalCard";
import {GroupsFaqModalCard} from "./modals/faq/client/GroupsFaqModalCard";
import {ActionsFaqModalCard} from "./modals/faq/client/ActionsFaqModalCard";
import {GiftsFaqModalCard} from "./modals/faq/client/GiftsFaqModalCard";
import {GiftCodeFaqModalCard} from "./modals/faq/client/GiftCodeFaqModalCard";

export const App = () => {
    const platform = usePlatform();
    const hasHeader = platform !== 'vkcom';
    const {viewWidth} = useAdaptivityConditionalRender();
    const routeNavigator = useRouteNavigator();

    const [faqIsCompleted, setFaqIsCompleted] = React.useState(localStorage.getItem('faqIsCompleted') ?? false);
    const [faqActiveModal, setFaqActiveModal] = React.useState('welcome_faq_modal_card');

    const {sizeX} = useAdaptivityConditionalRender();
    const {isDesktop} = useAdaptivityWithJSMediaQueries();
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
    const [popout, setPopout] = useState(null);
    const clearPopout = () => setPopout(null);

    const vkService = new VkService(setPopout);

    const { view: activeView } = useActiveVkuiLocation();
    const activePanel = useGetPanelForView();

    const { modal: activeModal } = useActiveVkuiLocation();
    const [subscription, setSubscription] = useState(null);

    const modal = (
        <ModalRoot activeModal={faqIsCompleted ? activeModal : faqActiveModal}>
            <WelcomeFaqModalCard id={'welcome_faq_modal_card'} setFaqActiveModal={setFaqActiveModal} />
            <GroupsFaqModalCard id={'groups_faq_modal_card'} setFaqActiveModal={setFaqActiveModal} />
            <ActionsFaqModalCard id={'actions_faq_modal_card'} setFaqActiveModal={setFaqActiveModal} />
            <GiftsFaqModalCard id={'gifts_faq_modal_card'} setFaqActiveModal={setFaqActiveModal} />
            <GiftCodeFaqModalCard id={'gifts_code_faq_modal_card'} setFaqActiveModal={setFaqActiveModal} />
            <EnterGiftCodeModal
                id={'enter_gift_code_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <UserUpdateModal
                id={'update_user_data_modal'}
                routeNavigator={routeNavigator}
                user={user}
                setUser={setUser}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <BoosterUpdateModal
                id={'booster_update_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <BoosterCreateModal
                id={'booster_create_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <BoosterQrCodeModal
                id={'booster_qr_code_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
            />
            <ActionCreateModal
                id={'action_create_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <ActionUpdateModal
                id={'action_update_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <GiftCreateModal
                id={'gift_create_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <GiftUpdateModal
                id={'gift_update_modal'}
                routeNavigator={routeNavigator}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <GiftShowQrCodeModal routeNavigator={routeNavigator} id={'gift_qr_code_modal'} />
            <GiftUserSelectModal
                id={'booster_gifts_modal'}
                routeNavigator={routeNavigator}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <ClientShowModal
                id={'client_show_modal'}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                routeNavigator={routeNavigator}
                setPopout={setPopout}
            />
            <ClientShowGiftsModal
                id={'client_show_gifts_modal'}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                routeNavigator={routeNavigator}
                setPopout={setPopout}
            />
            <ClientShowBoostersModal
                id={'client_show_actions_modal'}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                routeNavigator={routeNavigator}
                setPopout={setPopout}
            />
            <BuyGiftModal
                id={'buy_gift_modal'}
                sizeX={sizeX}
                isDesktop={isDesktop}
                platform={platform}
                routeNavigator={routeNavigator}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <VkActionModal
                id={'vk_action_modal'}
                setPopout={setPopout}
                clearPopout={clearPopout}
            />
            <UserAdminSettingsModal
                id={'admin_user_settings_modal'}
                setPopout={setPopout}
            />
            <VkGroupsFilerModal
                id={'vk_groups_filer_modal'}
                setPopout={setPopout}
            />
            <GiftCodeCreateModal
                id={'giftCode_create_modal'}
                setPopout={setPopout}
            />
            <VkGroupUpdateModal
                id={'admin_vk_group_update_modal'}
                setPopout={setPopout}
            />
            <LoyaltyLevelCreateModal
                id={'loyalty_level_create_modal'}
                setPopout={setPopout}
            />
            <LoyaltyLevelUpdateModal
                id={'loyalty_level_update_modal'}
                setPopout={setPopout}
            />
            <ListFilterModal
                id={'list_filter_modal'}
                setPopout={setPopout}
            />
            <VkUserScoresModal
                id={'vk_user_scores_modal'}
                setPopout={setPopout}
            />
            <ReferalSystemModal
                id={'referal_system_modal'}
                setPopout={setPopout}
            />
        </ModalRoot>
    );

    const fetchSubscriptionData = async () => {
        try {
            await vkService.checkSubscription().then(res => {
                setSubscription(res);
            });
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    }

    useEffect(() => {
        if (user) {
            fetchSubscriptionData();
        }
    }, []);

    const menuItems = [
        {
            route: '/',
            name: 'groups',
            title: 'Сообщества',
            icon: <Icon28Users3Outline />
        },
        {
            route: '/profile',
            name: 'profile',
            title: 'Профиль',
            icon: <Icon28UserCircleOutline/>
        },
        {
            route: '/gifts',
            name: 'gift',
            title: 'Подарки',
            icon: <Icon28GiftOutline/>
        }
    ];

    return (
        <Layout popout={popout} modal={modal} hasHeader={hasHeader}>
            <DesktopLayout
                routeNavigator={routeNavigator}
                user={user}
                subscription={subscription}
                viewWidth={viewWidth}
                hasHeader={hasHeader}
                menuItems={menuItems}
                activeStory={activeView}
                setPopout={setPopout}
            />
            <MobileLayout
                routeNavigator={routeNavigator}
                subscription={subscription}
                viewWidth={viewWidth}
                activeStory={activeView}
                menuItems={menuItems}
                user={user}
                activePanel={activePanel}
                setPopout={setPopout}
            />
        </Layout>
    )
};
