import {Footer} from "@vkontakte/vkui";
import * as React from "react";
import {numToWord} from "../../helpers/StrHelper";

export const LoyaltyLevelNextLevelInfo = ({group}) => {

    const likesCount = () => {
        let count = group.vkUserLoyaltyLevel.next.likes_count - group.likesCount;

        if (count > 0) {
            return count + ' ' + numToWord(count, ['лайк', 'лайков', 'лайков'])
        }

        return '';
    }

    const commentsCount = () => {
        let count = group.vkUserLoyaltyLevel.next.comments_count - group.commentsCount;

        if (count > 0) {
            return count + ' ' + numToWord(count, ['комментарий', 'каментариев', 'каментариев'])
        }

        return '';
    }

    const repostsCount = () => {
        let count = group.vkUserLoyaltyLevel.next.reposts_count - group.repostsCount;

        if (count > 0) {
            return count + ' ' + numToWord(count, ['репост', 'репостов', 'репостов'])
        }

        return '';
    }

    const reviewsCount = () => {
        let count = group.vkUserLoyaltyLevel.next.reviews_count - group.reviewsCount;

        if (count > 0) {
            return count + ' ' + numToWord(count, ['отзыв', 'отзывов', 'отзывов'])
        }

        return '';
    }

    return (
        group.vkUserLoyaltyLevel &&
        <Footer Component="div">
            {
                group.vkUserLoyaltyLevel.next ? 'До следующего уровня ' + likesCount() + ' ' + commentsCount() + ' ' + repostsCount() + ' ' + reviewsCount()
                : 'У вас максимальный уровень лояльности.'
            }

        </Footer>
    )
}