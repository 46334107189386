import {Avatar, Button, ButtonGroup, Div, Flex, FormItem, Group, HorizontalScroll, Panel, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Spinner, Tabs, TabsItem} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect, useState} from "react";
import {Icon24Search, Icon56UserAddOutline} from "@vkontakte/icons";
import VkGroupMemberService from "../../../services/vk/VkGroupMemberService";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {FormField} from "../../../components/form/FormField";
import {CustomPagination} from "../../../components/CustomPagination";

export const AdminVkUsersPanel = ({openError, routeNavigator}) => {
    const [fetching, setFetching] = useState(true);
    const [members, setMembers] = useState([]);
    const [paginationMeta, setPaginationMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const vkGroupMembersService = new VkGroupMemberService();
    const [formData, setFormData] = React.useState({});
    const params = useParams();

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const [selectedTab, setSelectedTab] = useState('lboosterMembers');

    const fetchPanelData = async () => {
        try {
            await vkGroupMembersService.all({
                vk_group_id: params.groupId,
                member_type: selectedTab,
                page: currentPage,
                member_name: formData.memberName ?? null
            }).then(res => {
                setMembers(res.data);
                setPaginationMeta(res.meta);
                setFetching(false);
            });
        } catch (error) {
            openError(error.message)
        }
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const onFilter = async () => {
        await fetchPanelData();
    }

    useEffect(() => {
        fetchPanelData();
    }, [selectedTab, currentPage]);

    return (
        <Panel>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Подписчики
            </PanelHeader>
            <Group>
                <Tabs
                    mode={'default'}
                    layoutFillMode={'auto'}
                    withScrollToSelectedTab
                    scrollBehaviorToSelectedTab="center"
                >
                    <HorizontalScroll arrowSize="m">
                        <TabsItem
                            selected={selectedTab === 'lboosterMembers'}
                            onClick={() => setSelectedTab('lboosterMembers')}
                        >
                            Подписчики в Lbooster
                        </TabsItem>
                        <TabsItem
                            selected={selectedTab === 'allMembers'}
                            onClick={() => setSelectedTab('allMembers')}
                        >
                            Все подписчики
                        </TabsItem>
                    </HorizontalScroll>
                </Tabs>
            </Group>
            <Group>
                <FormItem style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 0, paddingTop: 0}}>
                    <FormField
                        type="input"
                        name={'memberName'}
                        placeholder="Введите имя подписчика"
                        value={formData.memberName}
                        setValue={handler}
                    />
                    <Button
                        before={<Icon24Search />}
                        stretched={true}
                        appearance={'positive'}
                        size={'l'}
                        loading={fetching}
                        onClick={() => onFilter()}
                    />
                </FormItem>
            </Group>
            {
                !fetching ? <Group id="tab-content-clients" aria-labelledby="tab-groups" role="tabpanel">
                    {
                        members.length ?
                            <Div>
                                {
                                    members.map((member, index) => (
                                        <RichCell
                                            key={index}
                                            onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/members/show/' + member.id)}
                                            before={<Avatar size={72} src={member.photo} />}
                                            subhead={member.city}
                                            text={member.phone ? member.phone : 'Не указан'}
                                            after={member.loyaltyLevel ? member.loyaltyLevel.title : 'Не указан'}
                                        >
                                            {member.first_name + ' ' + member.last_name}
                                        </RichCell>
                                    ))
                                }
                                <CustomPagination
                                    currentPage={currentPage}
                                    paginationMeta={paginationMeta}
                                    onPageChange={onPageChange}
                                />
                            </Div>
                            : <Placeholder
                                icon={<Icon56UserAddOutline />}
                                header="Еще никто из подписчиков не использовал Lbooster"
                            >
                                Разместите QR-код на прохождение и наблюдайте как растет ваше сообщество.
                            </Placeholder>
                    }
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </Panel>
    )
}