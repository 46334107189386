import {Button, Image, ModalCard} from "@vkontakte/vkui";

export const ActionsFaqModalCard = ({id, setFaqActiveModal}) => {

    const changeActiveModal = (modal) => {
        setFaqActiveModal(modal)
    }

    const endFaq = () => {
        localStorage.setItem('faqIsCompleted', 1);
        setFaqActiveModal(null);
    }

    return (
        <ModalCard
            id={id}
            onClose={() => endFaq()}
            header="Действия"
            subheader={'В сообществе вы можете выполнять действия за которые начисляется определенное количество баллов.'}
            actions={
                <Button
                    size="l"
                    mode="primary"
                    stretched
                    onClick={() => changeActiveModal('gifts_faq_modal_card')}
                >
                    Далее
                </Button>
            }
        >
            <Image keepAspectRatio src={'https://sun9-50.userapi.com/impg/OG9LuVx_45b5zarq0KLMhNzoWIeZeOThDPk4dg/3F0K-SKnhHM.jpg?size=644x424&quality=95&sign=be78693ae98a8471dd264ef22ad0421b&type=album'} widthSize="100%" />
        </ModalCard>
    )
}