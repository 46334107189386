import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class VkAdminGroupService extends Service{
    basePath = 'vk/admin/groups'

    add = async (data) => {
        const response = await axios.post(this.basePath + '/add', data);
        return response.data.data;
    };

    addCallbackServer = async (data) => {
        const response = await axios.post('vk/api/groups/add-callback-server', data);
        return response.data.data;
    };
}

export default VkAdminGroupService;