import {Form} from "../form/Form";
import {FormField} from "../form/FormField";
import * as React from "react";
import {useEffect} from "react";
import GiftService from "../../services/gift/GiftService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import store from "../../store";
import {addAdminGiftInStore, updateAdminGiftInStore} from "../../features/gifts/adminGiftsSlice";
import {VkGroupSelect} from "../vk/VkGroupSelect";
import LoyaltyLevelService from "../../services/loyaltyLevels/LoyaltyLevelService";

export const GiftForm = ({setPopout}) => {
    const giftService = new GiftService(setPopout);
    const loyaltyLevelsService = new LoyaltyLevelService(setPopout);

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [formData, setFormData] = React.useState({
        vk_group_id: params.groupId
    });
    const [loading, setLoading] = React.useState(false);
    const [imagePreview, setImagePreview] = React.useState(null);
    const [loyaltyLevels, setLoyaltyLevels] = React.useState([]);

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'banner') {
            const reader = new FileReader();

            // Устанавливаем обработчик события на загрузку файла
            reader.onload = function(e) {
                setImagePreview(e.target.result);
            };

            // Читаем содержимое файла как Data URL
            reader.readAsDataURL(value);
        }
    };

    const update = async () => {
        await giftService.update(formData).then(res => {
            setTimeout(() => {
                store.dispatch(updateAdminGiftInStore(giftService.model))
                setLoading(false);
                routeNavigator.hideModal();
            }, 1000);
        }).catch(function (error) {
            setLoading(false);
        });
    }

    const create = async () => {
        await giftService.create(formData).then(res => {
            setTimeout(() => {
                store.dispatch(addAdminGiftInStore(giftService.model));
                setLoading(false);
                routeNavigator.hideModal();
            }, 1000);
        }).catch(function (error) {
            setLoading(false);
        });
    }

    const fetchData = async () => {
        if (params.uuid) {
            await giftService.show(params.uuid).then(res => {
                setFormData(giftService.model)
                setImagePreview(giftService.model.banner)
            });
        }
        await loyaltyLevelsService.all({vk_group_id: params.groupId}).then(res => {
            setLoyaltyLevels(loyaltyLevelsService.list);
        })
    }

    const onSubmit = async () => {
        if (params.uuid) {
            await update();
        } else {
            await create();
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Form mode="vertical" onSubmit={onSubmit} loading={loading}>
            <VkGroupSelect
                formData={formData}
                handler={handler}
            />
            <FormField
                title={'Название'}
                type={'input'}
                name={'name'}
                value={formData.name}
                setValue={handler}
            />
            <FormField
                title={'Описание'}
                type={'input'}
                name={'description'}
                value={formData.description}
                setValue={handler}
            />
            <FormField
                title={'Стоимость очков сообщества'}
                type={'inputNumber'}
                name={'amount'}
                value={formData.amount}
                setValue={handler}
                min={1}
                max={10000}
            />
            <FormField
                title={'Необходимый уровень лояльности'}
                type={'select'}
                name={'loyalty_level_uuid'}
                value={formData.loyalty_level_uuid}
                setValue={handler}
                options={loyaltyLevels}
                optionLabel={'title'}
                optionId={'uuid'}
            />
            <div style={{display: "flex"}}>
                <FormField
                    title={'Изображение подарка'}
                    type={'file'}
                    name={'banner'}
                    value={formData.banner}
                    imagePreview={imagePreview}
                    setValue={handler}
                    helpText={[
                        'Баннер отображается на странице сообщества.'
                    ]}
                />
            </div>
            <FormField
                title={'Количество дней на применение'}
                type={'inputNumber'}
                name={'count_days_to_apply'}
                value={formData.count_days_to_apply}
                setValue={handler}
                min={1}
                max={365}
                helpText={[
                    'Если оставить пустым, то ограничения по дням не будет.'
                ]}
            />
            <FormField
                title={'Тип отображения'}
                type={'segmentControl'}
                name={'show_type'}
                value={formData.show_type}
                setValue={handler}
                options={[
                    {
                        'label': 'Бустер',
                        'value': 'booster',
                        'aria-label': 'Бустер',
                    },
                    {
                        'label': 'Сообщество',
                        'value': 'group',
                        'aria-label': 'Сообщество',
                    },
                ]}
                helpText={[
                    'Если выбрать бустер, то действие будет доступно только для прохождения в бустере.',
                    'Если ввыбрать сообщество, то только на странице сообщества.'
                ]}
            />
        </Form>
    )
}