import {Group, PanelHeader, PanelHeaderBack, SimpleCell} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import * as React from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import {Icon28Cards2, Icon28Story} from "@vkontakte/icons";

export const VkGroupMaterialsPanel = ({...props}) => {

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()}/>}
                after={<VkUser user={props.user} mode={'plain'}/>}
            >
                Материалы
            </PanelHeader>
            <Group>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/groups/'+ params.groupId + '/materials/vk/story')}
                    expandable="auto"
                    before={<Icon28Story />}
                >
                    История
                </SimpleCell>
                <SimpleCell
                    onClick={() => routeNavigator.push('/admin/groups/'+ params.groupId + '/materials/vk/card')}
                    expandable="auto"
                    before={<Icon28Cards2 />}
                >
                    Карточка А4
                </SimpleCell>
            </Group>
        </CustomPanel>
    )
}