import {Panel, Snackbar, useAdaptivityConditionalRender, useAdaptivityWithJSMediaQueries} from "@vkontakte/vkui";
import {useEffect, useState} from "react";
import {Icon24ThumbsUpOutline, Icon28ErrorCircleOutline} from "@vkontakte/icons";
import * as React from "react";
import {useSelector} from "react-redux";
import store from "../../store";
import {clearAppErrors} from "../../features/errors/appErrorsSlice";
import {clearAppMessages} from "../../features/errors/appMessagesSlice";

export const CustomPanel = ({children, id}) => {
    const [popout, setPopout] = useState(null);
    const {isDesktop} = useAdaptivityWithJSMediaQueries();
    const [snackbar, setSnackbar] = React.useState(null);
    const appError = useSelector((state) => state.appErrors.error);
    const appMessage = useSelector((state) => state.appMessage.message);

    const openError = (message) => {
        if (snackbar) return;

        setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)"/>}
                offsetY={!isDesktop ? 50 : 0}
            >
                {message}
            </Snackbar>,
        );

        setTimeout(() => {
            store.dispatch(clearAppErrors())
        }, 2000)
    };

    const showAppMessage = (appMessage) => {
        if (snackbar) return;
        setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                subtitle={appMessage.subtitle}
                before={<Icon24ThumbsUpOutline fill="var(--vkui--color_icon_accent)" />}
                offsetY={!isDesktop ? 50 : 0}
            >
                {appMessage.message}
            </Snackbar>,
        );
    };

    useEffect(() => {
        if (appError) {
            openError(appError.message);
        }
        if (appMessage) {
            showAppMessage(appMessage);
            store.dispatch(clearAppMessages());
        }
    }, [appError, appMessage])

    return (
        <Panel id={id}>
            {children}
            {snackbar}
        </Panel>
    )
}