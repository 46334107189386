import {Button, ButtonGroup, Counter, Group, Header, Placeholder, SimpleCell, Spinner, Tooltip} from "@vkontakte/vkui";
import * as React from "react";
import {Icon28CommentCircleFillBlue, Icon28LikeCircleFillRed, Icon28RepostCircleFillGreen, Icon28UsersCircleFillBlue, Icon28UserStarOutline, Icon28ViewOutline, Icon28VotesTransferCircleFillTurquoise, Icon56StatisticsOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

const VkGroupStat = ({stat, loading, period, onChangePeriod}) => {
    return (
        <Group
            header={
                <Header aside={<ButtonGroup mode="horizontal" gap="m">
                                <Button
                                    onClick={() => onChangePeriod('daily')}
                                    appearance={period === 'daily' ? 'accent' : 'neutral'}
                                    mode={period === 'daily' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Сутки
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('weekly')}
                                    appearance={period === 'weekly' ? 'accent' : 'neutral'}
                                    mode={period === 'weekly' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Неделя
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('monthly')}
                                    appearance={period === 'monthly' ? 'accent' : 'neutral'}
                                    mode={period === 'monthly' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Месяц
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('all')}
                                    appearance={period === 'all' ? 'accent' : 'neutral'}
                                    mode={period === 'all' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Все время
                                </Button>
                            </ButtonGroup>}>
                    Сообщество
                </Header>
            }
        >
            {
                !loading ?
                    <>
                        <SimpleCell before={<Icon28UserStarOutline />} indicator={stat.members_in_lbooster}>
                            Подписчики в Lbooster
                        </SimpleCell>
                        <SimpleCell before={<Icon28UsersCircleFillBlue />} indicator={stat.members}>
                            Новые подписчики
                        </SimpleCell>
                        <SimpleCell before={<Icon28LikeCircleFillRed />} indicator={stat.likes_count}>
                            Лайков
                        </SimpleCell>
                        <SimpleCell before={<Icon28CommentCircleFillBlue />} indicator={stat.comments_count}>
                            Комментариев
                        </SimpleCell>
                        <SimpleCell before={<Icon28RepostCircleFillGreen />} indicator={stat.reposts_count}>
                            Репостов
                        </SimpleCell>
                    </> : <Spinner size={'medium'}/>
            }
        </Group>
    )
}

export default VkGroupStat;