import {Avatar, Button, ButtonGroup, Placeholder, Spinner, UsersStack} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Pencil, Icon12Switch, Icon56UsersOutline} from "@vkontakte/icons";
import ActionService from "../../services/action/ActionService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import {numToWord} from "../../helpers/StrHelper";

export const AdminActionsArchive = ({limit, groupId, showAllLink, addLink}) => {
    const actionService = new ActionService();
    const [fetching, setFetching] = React.useState(true);
    const [restoreLoading, setRestoreLoading] = React.useState(false);
    const [actions, setActions] = React.useState([]);
    const routeNavigator = useRouteNavigator();

    const params = useParams();

    const fetchPanelData = async () => {
        await actionService.deleted({limit: limit, vk_group_id: groupId}).then(res => {
            setActions(actionService.list);
            setFetching(false);
        });
    }

    const onClickRestoreAction = async (action) => {
        setRestoreLoading(true);
        await actionService.restore(action.uuid).then(res => {
            fetchPanelData().then(res => {
                setRestoreLoading(false)
            });
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Действия'}>
            {
                !fetching ?
                    actions.length ?
                        actions.map((action, index) => (
                            <GroupContentList
                                key={index}
                                content={action.name}
                                subhead={'Удален ' + action.deleted_at}
                                before={action.vkGroup ? <Avatar size={40} src={action.vkGroup.photo} /> : null}
                                text={action.vkGroup ? action.vkGroup.name : null}
                                caption={action.link ? action.link.link : null}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12Pencil />} onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/actions/update/' + action.uuid)} mode="primary" />
                                        <Button before={<Icon12Switch />} title={'Восстановить'} loading={restoreLoading} onClick={() => onClickRestoreAction(action)} mode="primary" />
                                    </ButtonGroup>
                                }
                                bottom={
                                    action.usersWithCompletedAction.length ?
                                        <UsersStack photos={action.usersWithCompletedAction}>
                                            {action.usersWithCompletedAction.length} {numToWord(action.usersWithCompletedAction.length , ['пользователь', 'пользователя', 'пользователей'])}
                                        </UsersStack>
                                        : null
                                }
                            />
                        )) :  <Placeholder icon={<Icon56UsersOutline />}>
                            Пока еще нет действий
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}