import {Group} from "@vkontakte/vkui";
import {FormField} from "../form/FormField";
import * as React from "react";
import {Form} from "../form/Form";
import {VkGroupSelect} from "../vk/VkGroupSelect";
import {useEffect} from "react";
import store from "../../store";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import LoyaltyLeveConditionService from "../../services/loyaltyLevels/LoyaltyLeveConditionService";
import LoyaltyLevelService from "../../services/loyaltyLevels/LoyaltyLevelService";
import {addAdminLoyaltyLevelsInStore, updateLoyaltyLevelsInStore} from "../../features/loyaltyLevels/adminLoyaltyLevelsSlice";

export const LoyaltyLevelForm = ({setPopout}) => {
    const loyaltyLevelConditionService = new LoyaltyLeveConditionService();
    const loyaltyLevelService = new LoyaltyLevelService(setPopout);

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [formData, setFormData] = React.useState({
        vk_group_id: params.groupId
    });

    const [loyaltyLevelConditions, setLoyaltyLevelConditions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchData = async () => {
        await loyaltyLevelConditionService.all().then(res => {
            setLoyaltyLevelConditions(loyaltyLevelConditionService.list);
        })

        if (params.uuid) {
            await loyaltyLevelService.show(params.uuid).then(res => {
                setFormData(loyaltyLevelService.model);
            });
        }
    }

    const update = async () => {
        try {
            await loyaltyLevelService.update(formData).then(res => {
                setTimeout(() => {
                    store.dispatch(updateLoyaltyLevelsInStore(loyaltyLevelService.model));
                    setLoading(false);
                    routeNavigator.hideModal()
                }, 1000);
            })
        } catch (error) {
            setLoading(false);
        }
    }

    const create = async () => {
        try {
            await loyaltyLevelService.create(formData).then(res => {
                setTimeout(() => {
                    store.dispatch(addAdminLoyaltyLevelsInStore(loyaltyLevelService.model));
                    setLoading(false);
                    routeNavigator.hideModal()
                }, 1000);
            })
        } catch (error) {
            setLoading(false);
        }
    }

    const onSubmit = async () => {
        setLoading(true);

        if (params.uuid) {
            await update();
        } else {
            await create();
        }
    }

    useEffect(() => {
        fetchData().then(res => {
            return res;
        })
    }, [])

    return (
        <Group>
            <Form mode="vertical" onSubmit={onSubmit} loading={loading}>
                <VkGroupSelect
                    formData={formData}
                    handler={handler}
                />
                <FormField
                    title={'Название'}
                    type={'input'}
                    name={'title'}
                    value={formData.title}
                    setValue={handler}
                />
                <FormField
                    title={'Описание'}
                    type={'textarea'}
                    name={'description'}
                    value={formData.description}
                    setValue={handler}
                />
                <FormField
                    title={'Порядковый номер'}
                    type={'inputNumber'}
                    name={'sort_number'}
                    value={formData.sort_number}
                    setValue={handler}
                    helpText={['Наример, Бронзовый уровень - 1, Серебряный уровень - 2, Золотой уровень - 3']}
                />
                {
                    loyaltyLevelConditions.length &&
                    loyaltyLevelConditions.map((item, index) => (
                        <FormField
                            key={item.id}
                            title={item.title}
                            type={'inputNumber'}
                            name={item.name}
                            value={formData[item.name]}
                            setValue={handler}
                            min={1}
                        />
                    ))
                }
            </Form>
        </Group>
    )
}