import React from 'react';
import { SimpleCell, Counter } from '@vkontakte/vkui';
import { useSpring, animated } from '@react-spring/web';
import {Icon28VotesTransferCircleFillTurquoise} from "@vkontakte/icons";
import {useSelector} from "react-redux";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const VkGroupScoreBalance = ({user}) => {
    const scoresCount = useSelector(state => state.vkGroupScores.count);
    const routeNavigator = useRouteNavigator();
    const params = useParams();

    // Анимация изменения числа, размера и позиции
    const { number, scale, y } = useSpring({
        number: scoresCount,  // Анимируем изменение числа
        scale: 1.5,           // Увеличиваем размер
        y: 0,                 // Начальная позиция по Y
        config: { tension: 170, friction: 26 },  // Конфигурация анимации
        from: { number: 0, scale: 0, y: -50 }, // Начальные значения
        to: { number: scoresCount, scale: 1, y: 0 }, // Конечные значения
    });

    return (
        <SimpleCell
            before={<Icon28VotesTransferCircleFillTurquoise/>}
            subtitle={'При нажатии откроется история начисления баллов.'}
            onClick={() => routeNavigator.push('/groups/' + params.groupId + '/scores/' + user.vk_user_uuid)}
            indicator={<Counter style={{transform: y.to((yPos) => `translateY(${yPos}px)`)}} mode="primary">
                <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
            </Counter>}
        >
            Баллы сообщества
        </SimpleCell>
    );
};
