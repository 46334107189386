import {Button, ModalCard, Image} from "@vkontakte/vkui";

export const GroupsFaqModalCard = ({id, setFaqActiveModal}) => {

    const changeActiveModal = (modal) => {
        setFaqActiveModal(modal)
    }

    const endFaq = () => {
        localStorage.setItem('faqIsCompleted', 1);
        setFaqActiveModal(null);
    }

    return (
        <ModalCard
            id={id}
            onClose={() => endFaq()}
            header="Сообщества"
            subheader={'В списке сообществ вы можете найти сообщества, которые используют Loyalty Booster. Выполнять действия и покупать подарки за баллы.'}
            actions={
                <Button
                    size="l"
                    mode="primary"
                    stretched
                    onClick={() => changeActiveModal('actions_faq_modal_card')}
                >
                    Далее
                </Button>
            }
        >
            <Image keepAspectRatio src={'https://sun9-62.userapi.com/impg/3uMAzZMbW7KrTkUytdGaVda1kLg9p3YX9NETZA/x1p8aUiVPAA.jpg?size=646x524&quality=95&sign=5abeffec6a2e1957f71d9e07ccd3bcdc&type=album'} widthSize="100%" />
        </ModalCard>
    )
}