import {FormLayoutGroup, Group, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderClose} from "@vkontakte/vkui";
import {Icon24Dismiss} from "@vkontakte/icons";
import * as React from "react";
import {useEffect} from "react";
import UserService from "../../services/user/UserService";
import {Form} from "../../components/form/Form";
import {FormField} from "../../components/form/FormField";
import CityService from "../../services/city/CityService";

export const UserUpdateModal = ({...props}) => {
    const userService = new UserService(props.setPopout)
    const cityService = new CityService(props.setPopout);

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState(props.user);
    const [cities, setCities] = React.useState([]);

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onSubmit = async () => {
        setLoading(true);
        await userService.update(formData).then(res => {
            localStorage.setItem('user', JSON.stringify(userService.model))
            props.setUser(userService.model);
            props.routeNavigator.hideModal()
            setLoading(false);
        })
    }

    const fetchData = async () => {
        await cityService.all().then(res => {
            setCities(cityService.list)
        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <ModalPage
            id={'update_user_data_modal'}
            onClose={() => props.routeNavigator.hideModal()}
            hideCloseButton={props.platform === 'ios'}
            settlingHeight={100}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    before={
                        props.sizeX.compact &&
                        props.platform === 'android' && (
                            <PanelHeaderClose className={props.sizeX.compact.className} onClick={() => props.routeNavigator.hideModal()} />
                        )
                    }
                    after={
                        props.platform === 'ios' && (
                            <PanelHeaderButton onClick={() => props.routeNavigator.hideModal()}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    Редактировать профиль
                </ModalPageHeader>
            }
        >

            <Group>
                <FormLayoutGroup>
                    <FormLayoutGroup mode="vertical">
                        <Form loading={loading} onSubmit={onSubmit}>
                            <FormField
                                title={'Имя'}
                                type={'input'}
                                value={formData.name}
                                name={'name'}
                                setValue={handler}
                            />
                            <FormField
                                title={'Телефон'}
                                type={'input'}
                                value={formData.phone}
                                name={'phone'}
                                setValue={handler}
                            />
                            <FormField
                                title={'Email'}
                                type={'input'}
                                value={formData.email}
                                name={'email'}
                                setValue={handler}
                                disabled={loading || formData.email !== null}
                            />
                            <FormField
                                title={'Город по умолчанию'}
                                type={'customSelect'}
                                placeholder="Введите название города"
                                searchable
                                options={cities}
                                name={'city_id'}
                                value={formData.city_id}
                                setValue={handler}
                            />
                        </Form>
                    </FormLayoutGroup>
                </FormLayoutGroup>
            </Group>
        </ModalPage>
    )
}
