import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {Form} from "../../components/form/Form";
import {FormField} from "../../components/form/FormField";
import {useEffect} from "react";
import VkUserService from "../../services/vk/VkUserService";
import GiftService from "../../services/gift/GiftService";
import GiftCodeService from "../../services/gift/GiftCodeService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const GiftCodeCreateModal = ({...props}) => {
    const [formData, setFormData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [vkUsers, setVkUsers] = React.useState([]);
    const [gifts, setGifts] = React.useState([]);

    const params = useParams();

    const vkUserService = new VkUserService();
    const giftsService = new GiftService();
    const giftCodeService = new GiftCodeService(props.setPopout);

    const routeNavigator = useRouteNavigator();

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onSubmit = async () => {
        await giftCodeService.create(formData);
        return routeNavigator.hideModal();
    }

    const fetchPanelData = async () => {
        await vkUserService.all({vk_group_id: params.groupId}).then(res => {
            setVkUsers(vkUserService.list)
        })
        await giftsService.all({vk_group_id: params.groupId}).then(res => {
            setGifts(giftsService.list)
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, [])

    return (
        <CustomModal id={'giftCode_create_modal'} headerText={'Сгенерировать купон'}>
            <Form mode="vertical" onSubmit={onSubmit} loading={loading} submitButtonText={'Сгенерировать'}>
                <FormField
                    title={'Пользователь'}
                    type={'select'}
                    name={'vk_user_id'}
                    value={formData.vk_user_id}
                    setValue={handler}
                    options={vkUsers}
                    optionLabel={'name'}
                    optionId={'id'}
                    optionImage={'photo'}
                />
                <FormField
                    title={'Подарок'}
                    type={'select'}
                    name={'gift_id'}
                    value={formData.gift_id}
                    setValue={handler}
                    options={gifts}
                    optionLabel={'name'}
                    optionId={'id'}
                    optionImage={'banner'}
                />
            </Form>
        </CustomModal>
    )
}