import { createSlice } from '@reduxjs/toolkit'

export const appMessagesSlice = createSlice({
    name: 'appMessages',
    initialState: {
        message: null,
    },
    reducers: {
        addAppMessage: (state, action) => {
            console.log(action.payload)
            state.message = {
                message: action.payload.message,
                subtitle: action.payload.subtitle ?? null,
                time: new Date().getTime()
            }
        },
        clearAppMessages: (state) => {
            state.message = null
        },
    },
})

// Action creators are generated for each case reducer function
export const { addAppMessage, clearAppMessages } = appMessagesSlice.actions

export default appMessagesSlice.reducer