import axios from "../../plugins/axios-plugin";

export const allClientsGroups = async (groupsType) => {
    try {
        const response = await axios.get('vk/client/groups/all', {
            params: groupsType
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

export const showClientGroup = async (groupId) => {
    try {
        const response = await axios.get('vk/client/groups/show/' + groupId);
        return response.data.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};