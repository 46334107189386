import {Button, Image, ModalCard} from "@vkontakte/vkui";

export const GiftsFaqModalCard = ({id, setFaqActiveModal}) => {

    const changeActiveModal = (modal) => {
        setFaqActiveModal(modal);
    }

    const endFaq = () => {
        localStorage.setItem('faqIsCompleted', 1);
        setFaqActiveModal(null);
    }

    return (
        <ModalCard
            id={id}
            onClose={() => endFaq()}
            header="Магазин"
            subheader="В магазине вы можете покупать разные купоны, например на скидки или подарки."
            actions={
                <Button
                    size="l"
                    mode="primary"
                    stretched
                    onClick={() => changeActiveModal('gifts_code_faq_modal_card')}
                >
                    Далее
                </Button>
            }
        >
            <Image keepAspectRatio src={'https://sun9-26.userapi.com/impg/2yWUJJj_cUAbydwzYLWX_lII0rB9RErjhLNDYQ/mNJiSvhCbt4.jpg?size=424x250&quality=95&sign=1e5a64f24151c1d38a642dd9da635416&type=album'} widthSize="100%" />
        </ModalCard>
    )
}