import {Banner, Button, ButtonGroup, CellButton, Div, Group, Image, Separator, SimpleCell, Spacing} from "@vkontakte/vkui";
import {Icon28HomeOutline, Icon28MailOutline, Icon28PhoneOutline, Icon28WalletOutline} from "@vkontakte/icons";
import * as React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const UserCard = ({ user, subscription }) => {
    const routeNavigator = useRouteNavigator();

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();
    }

    return (
        <Group>
            <SimpleCell indicator={user.phone} before={<Icon28PhoneOutline />}>
                Номер телефона
            </SimpleCell>
            <SimpleCell indicator={user.email} before={<Icon28MailOutline />}>
                Email
            </SimpleCell>
            <SimpleCell indicator={user.city} before={<Icon28HomeOutline />}>
                Город
            </SimpleCell>
            {
                subscription && subscription.status === 'active' ?
                    <>
                        <Spacing size={24}>
                            <Separator />
                        </Spacing>
                        <SimpleCell indicator={'Активна до ' + subscription.next_payment_date } before={<Icon28WalletOutline />}>
                            Платная подписка
                        </SimpleCell>
                    </> : null
            }
            {
                subscription && subscription.status !== 'active' ?
                    <Div>
                        <Banner
                            header="Платная подписка"
                            subheader="Создавайте действия, подарки, собирайте отзывы, анализируйте свою аудиторию и сообщество."
                            actions={
                                <ButtonGroup mode="horizontal" gap="m">
                                    <Button onClick={() => window.open('https://vk.com/donut/lbooster', '_blank')} size="m">Активировать</Button>
                                    <Button onClick={() => routeNavigator.push('/user/enter-gift-code')} appearance={'neutral'} stretched={true} size="m">Ввести промокод</Button>
                                </ButtonGroup>
                            }
                        />
                    </Div> : null
            }
            <Spacing size={24}>
                <Separator />
            </Spacing>
            {/*<Group*/}
            {/*    mode={'plain'}*/}
            {/*    description="Обратите внимание, что редактирование профиля произойдет только в сервисе Lbooster."*/}
            {/*>*/}
            {/*    <CellButton onClick={() => routeNavigator.push('/user/update')}>Редактировать профиль</CellButton>*/}
            {/*</Group>*/}
            {
                user.vk_user_uuid && <Group
                    mode={'plain'}
                    description={
                        'Ваша реферальная ссылка: https://app.lbooster.ru?referal_id=' + user.vk_user_uuid
                    }
                >
                </Group>
            }
            <Group mode={'plain'}>
                <CellButton onClick={() => logout()} style={{color: 'tomato'}}>Выйти из профиля</CellButton>
            </Group>
        </Group>
    )
}