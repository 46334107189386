import {CustomModal} from "../../components/vkUI/CustomModal";
import {Group} from "@vkontakte/vkui";
import {CustomSpinner} from "../../components/vkUI/CustomSpinner";
import * as React from "react";
import {FormField} from "../../components/form/FormField";
import {Form} from "../../components/form/Form";
import ReferalSystemService from "../../services/referalSystem/ReferalSystemService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";

export const ReferalSystemModal = ({id, setPopout}) => {
    const service = new ReferalSystemService(setPopout);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        module_type: 'vk'
    });
    const routeNavigator = useRouteNavigator();

    const params = useParams();

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchData = async () => {
        if (params.referalSystemUuid) {
            await service.show(params.referalSystemUuid).then(res => {
                setFormData(service.model);
            })
        }
        handler('community_id', params.groupId)
    }

    const onSubmit = async () => {
        if (params.referalSystemUuid) {
            await update();
        } else {
            await create();
        }
    }

    const create = async () => {
        try {
            await service.create(formData).then(res => {
                setTimeout(() => {
                    setLoading(false);
                    routeNavigator.hideModal()
                }, 1000);
            })
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    const update = async () => {
        try {
            await service.update(formData).then(res => {
                setTimeout(() => {
                    setLoading(false);
                    routeNavigator.hideModal()
                }, 1000);
            })
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <CustomModal id={id} headerText={'Реферальная система'}>
            {
                !loading ?
                    <Group>
                        <Form onSubmit={onSubmit}>
                            <FormField
                                title={'Тип вознаграждения'}
                                service={service}
                                type={'segmentControl'}
                                name={'referal_reward_type'}
                                value={formData.referal_reward_type}
                                setValue={handler}
                                options={[
                                    {
                                        'label': 'Единоразово',
                                        'value': 'single',
                                        'aria-label': 'Единоразово',
                                    },
                                    {
                                        'label': 'Постоянно',
                                        'value': 'always',
                                        'aria-label': 'Постоянно',
                                    },
                                ]}
                                helpText={[
                                    'Если выбрать Единоразово, то пользователь получит вознаграждений один раз.',
                                    'Если выбрать Постоянно, то пользователь будет получать вознаграждение каждый раз при выполнении действий.',
                                ]}
                            />
                            <FormField
                                title={'Сумма вознаграждения'}
                                type={'inputNumber'}
                                name={'referal_reward'}
                                value={formData.referal_reward}
                                setValue={handler}
                                min={1}
                                max={10000}
                            />
                        </Form>
                    </Group> : <CustomSpinner />
            }
        </CustomModal>
    )
}