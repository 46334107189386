import {Button, ModalCard} from "@vkontakte/vkui";

export const WelcomeFaqModalCard = ({id, setFaqActiveModal}) => {

    const changeActiveModal = (modal) => {
        setFaqActiveModal(modal)
    }

    const endFaq = () => {
        localStorage.setItem('faqIsCompleted', 1);
        setFaqActiveModal(null);
    }

    return (
        <ModalCard
            id={id}
            onClose={() => endFaq()}
            header="Добро пожаловать в Loyalty Booster"
            subheader="Мы подготовили небольшую экскурсию по функционалу приложения. Вы можете закрыть ее нажатием на крестик или ознакомиться нажимая Далее."
            actions={
                <Button
                    size="l"
                    mode="primary"
                    stretched
                    onClick={() => changeActiveModal('groups_faq_modal_card')}
                >
                    Далее
                </Button>
            }
        />
    )
}