import {Group, PanelHeader, PanelHeaderBack, Header, CardGrid} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect, useState} from "react";
import BoosterSessionService from "../../services/booster/BoosterSessionService";
import GiftService from "../../services/gift/GiftService";
import {VkGroupCard} from "../../components/vk/VkGroupCard";
import {GiftBanner} from "../../components/gift/GiftBanner";
import {VkUser} from "../../components/VkUser";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {CustomCard} from "../../components/vkUI/CustomCard";
import {CustomPanel} from "../../components/vkUI/CustomPanel";

export const BoosterShowPanel = ({...props}) => {
    const giftService = new GiftService(props.setPopout);
    const boosterSesionService = new BoosterSessionService(props.setPopout);

    const routeNavigator = useRouteNavigator();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [booster, setBooster] = useState();

    const onClickAction = async (action) => {
        await routeNavigator.push( '/boosters/show/' + booster.uuid + '/action/' + action.uuid + '?booster_uuid=' + booster.uuid);
    }

    const checkBoosterIsCompleted = () => {
        if (booster && booster.actions.length) {
            let completedActions = booster.actions.filter(action => action.is_completed);
            return completedActions.length === booster.actions.length;
        }
    }

    const showGift = () => {
        props.routeNavigator.push(`/gift/code/show/` + booster.gift.id);
    }

    const getGift = () => {
        if (booster.gift_get_type === 'random') {
            giftService.getRandomGift({booster_uuid: booster.uuid}).then(res=> {
                props.routeNavigator.push(`/gift/code/show/` + res.uuid);
            });
        } else if (booster.gift_get_type === 'user_choice') {
            props.routeNavigator.push('/boosters/show/' + booster.uuid + '/gifts');
        }
    }

    useEffect(() => {
        boosterSesionService.startBusterSession(params.boosterUuid).then(res => {
            setBooster(res.booster);
        }).then(res => {
            setLoading(false);
        });
    }, []);

    return (
        <CustomPanel id={'boosterShowPanel'}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => props.routeNavigator.back()} />}
                after={<VkUser user={props.user} mode={'plain'} />}
            >
                Пройти бустер
            </PanelHeader>
            <VkGroupCard vkGroup={booster ? booster.vkGroup : null} />
            <GiftBanner
                isShow={checkBoosterIsCompleted() && !booster.userHasBoosterGift}
                getGift={getGift}
                showGift={showGift} />
            <Group>
                <Header mode="primary" size="large">
                    Действия
                </Header>
                <CardGrid size="s" style={{marginTop: 10}}>
                    {
                        booster && booster.actions.map((action, index) => (
                            <CustomCard
                                key={index}
                                onClick={() => !action.is_completed ? onClickAction(action) : null}
                                image={action.banner}
                                title={action.name}
                                checked={action.is_completed}
                                scores_count={action.scores_count}
                            />
                        ))
                    }
                </CardGrid>
            </Group>
        </CustomPanel>
    )
}