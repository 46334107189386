import {Group, Header, SimpleCell, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon28LikeCircleFillRed, Icon28UsersCircleFillBlue, Icon28UserStarOutline} from "@vkontakte/icons";

const LboosterStat = ({stat, loading}) => {
    return (
        <Group
            header={
                <Header>
                    Общая
                </Header>
            }
        >
            {
                !loading ?
                    <>
                        <SimpleCell before={<Icon28UserStarOutline />} indicator={stat.group_views}>
                            Просмотров сообщества
                        </SimpleCell>
                        <SimpleCell before={<Icon28UsersCircleFillBlue />} indicator={stat.actions_is_completed}>
                            Выполненно действий
                        </SimpleCell>
                        <SimpleCell before={<Icon28LikeCircleFillRed />} indicator={stat.gift_sell}>
                            Куплено подарков
                        </SimpleCell>
                    </> : <Spinner size={'medium'}/>
            }
        </Group>
    )
}

export default LboosterStat;