import {CustomModal} from "../components/vkUI/CustomModal";

export const ListFilterModal = (props) => {
    return (
        <CustomModal
            id={'list_filter_modal'}
            headerText={'Фильтры'}
        >

        </CustomModal>
    )
}