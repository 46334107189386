import {Cell, Group, Panel, PanelHeader, SplitCol} from "@vkontakte/vkui";
import * as React from "react";
import {Icon28Settings} from "@vkontakte/icons";
import logo from "../../../assets/logo.png";

export const DesktopLayout = ({routeNavigator, user, viewWidth, subscription, hasHeader, menuItems, activeStory}) => {
    const activeStoryStyles = {backgroundColor: 'var(--vkui--color_background_secondary)', borderRadius: 8,};

    return (
        viewWidth.tabletPlus && (
            <SplitCol className={viewWidth.tabletPlus.className} fixed width={280} maxWidth={280}>
                <Panel>
                    {hasHeader && <PanelHeader before={<img width={200} src={logo} alt={'Логотип Lbooster'} />} />}
                    {
                        user ?
                            <Group>
                                {
                                    menuItems.map((item, index) => (
                                        <Cell
                                            key={index}
                                            disabled={activeStory === item.name}
                                            style={activeStory === item.name ? activeStoryStyles : undefined}
                                            data-story={item.name}
                                            onClick={() => routeNavigator.push(item.route)}
                                            before={item.icon}
                                        >
                                            {item.title}
                                        </Cell>
                                    ))
                                }
                                {
                                    subscription && subscription.status === 'active' ?
                                        <Cell
                                            disabled={activeStory === 'admin'}
                                            style={activeStory === 'admin' ? activeStoryStyles : undefined}
                                            data-story={'admin'}
                                            onClick={() => routeNavigator.push('/admin')}
                                            before={<Icon28Settings />}
                                        >
                                            Панель управления
                                        </Cell> : null
                                }
                            </Group> : null
                    }
                </Panel>
            </SplitCol>
        )
    )
}