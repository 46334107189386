import { createSlice } from '@reduxjs/toolkit'

export const vkGroupActionsSlice = createSlice({
    name: 'vkGroupActionsSlice',
    initialState: {
        list: [],
    },
    reducers: {
        setVkGroupActionsSliceInStore: (state, action) => {
            state.list = action.payload;
        },
        updateVkGroupActionsSliceInStore(state, action) {
            state.list = state.list.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        ...action.payload
                    };
                }
                return item;
            });
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setVkGroupActionsSliceInStore,
    updateVkGroupActionsSliceInStore
} = vkGroupActionsSlice.actions

export default vkGroupActionsSlice.reducer