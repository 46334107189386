import {Button, Div, Flex, Group, PanelHeader, PanelHeaderBack, useAdaptivityWithJSMediaQueries} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import * as React from "react";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import cardBg from "../../../assets/default_card.png";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import vkQr from "@vkontakte/vk-qr";
import html2canvas from 'html2canvas';
import {showClientGroup} from "../../../services/vk/VkClientGroupService";
import {useEffect} from "react";
import {CustomSpinner} from "../../../components/vkUI/CustomSpinner";

export const AdminVkGroupCardPanel = ({...props}) => {
    const {isDesktop} = useAdaptivityWithJSMediaQueries();
    const [fetching, setFetching] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [group, setGroup] = React.useState(null);

    const fetchPanelData = async () => {
        await showClientGroup(params.groupId).then((response) => {
            setGroup(response)
            setFetching(false)
        })
    }

    const routeNavigator = useRouteNavigator();

    const params = useParams();

    const getQrCode = () => {
        return vkQr.createQR('https://app.lbooster.ru/groups/' + params.groupId, {
            qrSize: 256,
            foregroundColor: 'white',
            isShowLogo: true,
            // logoData: group.photo
        });
    }

    const takeScreenshot = () => {
        setLoading(true);
        const container = document.getElementById('story'); // замените на ID вашего контейнера

        html2canvas(container).then(async (canvas) => {
            const screenshotUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = screenshotUrl;
            link.download = 'screenshot.png';
            link.click();

            setLoading(false);
        });
    };

    useEffect(() => {
        fetchPanelData();
    }, []);

    const containerStyle = {
        position: 'relative',
        width: '100%',  // Ширина контейнера
        height: '100%', // Высота контейнера
        overflow: 'hidden',
    };

    const backgroundImageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Изображение будет покрывать весь контейнер
    };

    const qrCodeStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Центрируем QR-код
        zIndex: 1, // Обеспечим, что QR-код будет выше изображения
    };

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()}/>}
                after={<VkUser user={props.user} mode={'plain'}/>}
            >
                Шаблон карточки А4
            </PanelHeader>

            {
                !fetching ?
                    <>
                        <div id={'story'} style={containerStyle}>
                            <img
                                src={cardBg}
                                alt="Background"
                                style={backgroundImageStyle}
                            />
                            <div style={qrCodeStyle}>
                                <div dangerouslySetInnerHTML={{__html: getQrCode()}}/>
                            </div>
                        </div>
                        <Div style={{marginTop: 50}}>
                            <Flex align={'center'} justify={'center'}>
                                <Button onClick={takeScreenshot} loading={loading}>
                                    Скачать шаблон
                                </Button>
                            </Flex>
                        </Div>
                    </>
                    : <CustomSpinner/>
            }

        </CustomPanel>
    )
}