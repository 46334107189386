import {PanelHeader, ScreenSpinner} from "@vkontakte/vkui";
import * as React from "react";
import * as VKID from '@vkid/sdk';
import {useEffect} from "react";
import axios from 'axios';
import {CustomPanel} from "../../components/vkUI/CustomPanel";
import {AuthPlaceholder} from "../../components/auth/AuthPlaceholder";

export const AuthPanel = ({...props}) => {
    const clearQueryParams = () => {
        window.location.replace(localStorage.getItem('redirect_url'));
    };

    const getUser = () => {
        props.setPopout(<ScreenSpinner state="loading" />);

        axios.get( process.env.NODE_ENV === 'development' ? 'http://api.localhost/api/user' : 'https://api.lbooster.ru/api/user', {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
            params: {referal_id: localStorage.getItem('referal_id') ?? null}
        })
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data.data.user));
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Ошибка:', error);
            })
            .finally(() => {
                clearQueryParams();
                localStorage.removeItem('referal_id');
                props.setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                setTimeout(props.clearPopout, 1000);
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const referal_id = queryParams.get('referal_id');

        if (!token) {
            localStorage.setItem('redirect_url', window.location.href);
        }

        if (referal_id) {
            localStorage.setItem('referal_id', referal_id);
        }

        if (token) {
            localStorage.setItem('token', token);
            if (localStorage.getItem('token')) {
                getUser();
            }
        } else {
            VKID.Config.set({
                app: 51807747, // Идентификатор приложения.
                redirectUrl: process.env.NODE_ENV === 'development' ? 'http://api.localhost/login/vk' : 'https://api.lbooster.ru/login/vk',
                state: 'dj29fnsadjsd82...' // Произвольная строка состояния приложения.
            });

            const oneTap = new VKID.OneTap();
            const container = document.getElementById('VkIdSdkOneTap');
            if (container) {
                oneTap.render({ container: container, scheme: VKID.Scheme.LIGHT, lang: VKID.Languages.RUS });
            }
        }
    }, []);

    return (
        <CustomPanel>
            <PanelHeader>
                Авторизация
            </PanelHeader>
            <AuthPlaceholder />
        </CustomPanel>
    )
}