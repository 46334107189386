import { createSlice } from '@reduxjs/toolkit'

export const vkGroupScoresSlice = createSlice({
    name: 'vkGroupScoresSlice',
    initialState: {
        count: null,
    },
    reducers: {
        setVkGroupScoresInStore: (state, action) => {
            state.count = action.payload;
        },
        updateVkGroupScoresInStore(state, action) {
            state.count += action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setVkGroupScoresInStore,
    updateVkGroupScoresInStore
} = vkGroupScoresSlice.actions

export default vkGroupScoresSlice.reducer