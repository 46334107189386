import {Avatar, CellButton, Group, Header, InfoRow, PanelHeader, PanelHeaderBack, Placeholder, RichCell, SimpleCell, Skeleton, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Icon28CheckCircleFill, Icon28GifOutline, Icon28Services} from "@vkontakte/icons";
import VkGroupMemberService from "../../../services/vk/VkGroupMemberService";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";

export const VkGroupMemberShowGiftsPanel = ({...props}) => {
    const vkGroupMemberService = new VkGroupMemberService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [memberGifts, setMemberGifts] = React.useState([]);

    const fetchData = async () => {
        await vkGroupMemberService.showGifts(params.uuid).then(res => {
            setMemberGifts(res)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Полученные подарки
            </PanelHeader>
            {
                !fetching ? <Group>
                    {
                        memberGifts.length ? memberGifts.map((memberGift, index) => (
                            <RichCell
                                key={index}
                                subhead={memberGift.vkGroup.name}
                                before={
                                    <Avatar size={72} src={memberGift.vkGroup.photo}>
                                        {
                                            memberGift.is_used ?
                                                <Avatar.Badge>
                                                    <Icon28CheckCircleFill />
                                                </Avatar.Badge> : null
                                        }
                                    </Avatar>
                                }
                                text="Дата получения"
                                caption={memberGift.created_at}
                            >
                                {memberGift.name}
                            </RichCell>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </CustomPanel>
    )
}