import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class VkApiGroupService extends Service{
    basePath = 'vk/api/groups'

    allWithAdmin = async () => {
        return await axios.get(this.basePath + '/all-with-admin');
    }
}

export default VkApiGroupService;