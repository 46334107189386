import ActionService from "../../services/action/ActionService";
import VkAdminGroupService from "../../services/vk/VkAdminGroupService";
import GiftService from "../../services/gift/GiftService";
import BoosterService from "../../services/booster/BoosterService";
import * as React from "react";
import store from "../../store";
import {addAdminBoosterInStore, updateAdminBoosterInStore} from "../../features/boosters/adminBoostersSlice";
import {useEffect} from "react";
import {FormField} from "../form/FormField";
import {Button, FormLayoutGroup, Group} from "@vkontakte/vkui";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Form} from "../form/Form";

export const BoosterForm = ({setPopout}) => {
    const actionService = new ActionService(setPopout);
    const vkAdminGroupService = new VkAdminGroupService(setPopout);
    const giftService = new GiftService(setPopout);
    const boosterService = new BoosterService(setPopout);

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [fetching, setFetching] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const [selectedActions, setSelectedActions] = React.useState([]);
    const [selectedGifts, setSelectedGifts] = React.useState([]);

    const [actions, setActions] = React.useState([]);
    const [gifts, setGifts] = React.useState([]);
    const [groups, setGroups] = React.useState(null);
    const [imagePreview, setImagePreview] = React.useState(null);

    const [formData, setFormData] = React.useState({
        vk_group_id: params.groupId
    });

    const handleChange = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'banner') {
            const reader = new FileReader();

            // Устанавливаем обработчик события на загрузку файла
            reader.onload = function(e) {
                setImagePreview(e.target.result);
            };

            // Читаем содержимое файла как Data URL
            reader.readAsDataURL(value);
        }
    };

    const fetchData = async () => {
        if (params.uuid) {
            await boosterService.show(params.uuid).then(async res => {
                setFormData(boosterService.model);
                setSelectedActions(boosterService.model.actions);
                setSelectedGifts(boosterService.model.gifts);
                setImagePreview(boosterService.model.banner);
            });
        }
        await vkAdminGroupService.all().then(res => {
            setGroups(vkAdminGroupService.list);
        });
        await actionService.all({show_type: 'booster'}).then(res => {
            setActions(actionService.list);
        });
        await giftService.all({show_type: 'booster'}).then(res => {
            setGifts(giftService.list);
            setFetching(false)
        });
    }

    const create = async () => {
        setLoading(true);

        formData.action_ids = [];
        formData.gift_ids = [];

        if (selectedActions) {
            selectedActions.map((item) => {return formData.action_ids.push(item.value)});
        }
        if (selectedGifts) {
            selectedGifts.map((item) => {return formData.gift_ids.push(item.value)});
        }

        await boosterService.create(formData).then(res => {
            setTimeout(() => {
                store.dispatch(addAdminBoosterInStore(boosterService.model))
                setLoading(false);
                routeNavigator.hideModal();
            }, 1000);
        }).catch(function (error) {
            setLoading(false)
        });
    }

    const update = async () => {
        setLoading(true);
        formData.action_ids = [];
        formData.gift_ids = [];

        selectedActions.map((item) => {return formData.action_ids.push(item.value)});
        selectedGifts.map((item) => {return formData.gift_ids.push(item.value)});

        await boosterService.update(formData).then(res => {
            setTimeout(() => {
                store.dispatch(updateAdminBoosterInStore(boosterService.model))
                routeNavigator.hideModal();
            }, 1000);
        }).catch(function (error) {
            setLoading(false)
        });
    }

    const onSubmit = async () => {
        if (params.uuid) {
            await update();
        } else {
            await create();
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Form mode="vertical" onSubmit={onSubmit} loading={loading}>
            <FormField
                title={'Название'}
                type={'input'}
                name={'name'}
                value={formData.name}
                setValue={handleChange}
            />
            <FormField
                title={'Сообщество'}
                type={'select'}
                name={'vk_group_id'}
                value={formData.vk_group_id}
                setValue={handleChange}
                options={groups}
                optionLabel={'name'}
                optionId={'group_id'}
                optionImage={'photo'}
            />
            <FormField
                title={'Действия'}
                type={'chipsSelect'}
                name={'action_ids'}
                value={selectedActions}
                setValue={setSelectedActions}
                options={actions}
                optionLabel={'label'}
                optionId={'value'}
                optionText={'link'}
            />
            <FormField
                title={'Подарки'}
                type={'chipsSelect'}
                name={'gift_ids'}
                value={selectedGifts}
                setValue={setSelectedGifts}
                options={gifts}
                optionLabel={'name'}
                optionId={'id'}
            />
            <FormField
                title={'Тип получения подарка'}
                type={'segmentControl'}
                name={'gift_get_type'}
                value={formData.gift_get_type}
                setValue={handleChange}
                options={[
                    {
                        'label': 'Случайно',
                        'value': 'random',
                        'aria-label': 'Случайно',
                    },
                    {
                        'label': 'Выбор подписчиком',
                        'value': 'user_choice',
                    },
                ]}
            />
            <FormField
                title={'Изображение для бустера'}
                type={'file'}
                name={'banner'}
                value={formData.banner}
                imagePreview={imagePreview}
                setValue={handleChange}
            />
            <FormField
                title={'Статус'}
                type={'switch'}
                name={'is_active'}
                value={formData.is_active}
                setValue={handleChange}
            />
        </Form>
    )
}