import {Group} from "@vkontakte/vkui";
import {FormField} from "../form/FormField";
import * as React from "react";
import {Form} from "../form/Form";
import {VkGroupSelect} from "../vk/VkGroupSelect";
import ActionTypeService from "../../services/action/ActionTypeService";
import {useEffect} from "react";
import store from "../../store";
import {addAdminActionInStore, updateAdminActionInStore} from "../../features/actions/adminActionsSlice";
import ActionService from "../../services/action/ActionService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const ActionForm = ({setPopout}) => {
    const actionTypeService = new ActionTypeService();
    const actionService = new ActionService(setPopout);

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [formData, setFormData] = React.useState({
        vk_group_id: params.groupId
    });
    const [types, setTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [imagePreview, setImagePreview] = React.useState(null);

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'banner') {
            const reader = new FileReader();

            // Устанавливаем обработчик события на загрузку файла
            reader.onload = function(e) {
                setImagePreview(e.target.result);
            };

            // Читаем содержимое файла как Data URL
            reader.readAsDataURL(value);
        }
    };

    const fetchData = async () => {
        await actionTypeService.all().then(res => {
            setTypes(actionTypeService.list);
        })

        if (params.uuid) {
            await actionService.show(params.uuid).then(res => {
                setFormData(actionService.model);
                setImagePreview(actionService.model.banner)
            });
        }
    }

    const update = async () => {
        try {
            await actionService.update(formData).then(res => {
                setTimeout(() => {
                    store.dispatch(updateAdminActionInStore(actionService.model));
                    setLoading(false);
                    routeNavigator.hideModal()
                }, 1000);
            })
        } catch (error) {
            setLoading(false);
        }
    }

    const create = async () => {
        try {
            await actionService.create(formData).then(res => {
                setTimeout(() => {
                    store.dispatch(addAdminActionInStore(actionService.model));
                    setLoading(false);
                    routeNavigator.hideModal()
                }, 1000);
            })
        } catch (error) {
            setLoading(false);
        }
    }

    const onSubmit = async () => {
        setLoading(true);

        if (params.uuid) {
            await update();
        } else {
            await create();
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Group>
            <Form mode="vertical" onSubmit={onSubmit} loading={loading}>
                <VkGroupSelect
                    formData={formData}
                    handler={handler}
                />
                <FormField
                    title={'Тип действия'}
                    type={'customSelect'}
                    name={'action_type_id'}
                    value={formData.action_type_id}
                    setValue={handler}
                    options={types}
                />
                <FormField
                    title={formData.action_type_id != 9 ? 'Ссылка на пост' : 'URL'}
                    visible={formData.action_type_id == 4 || formData.action_type_id == 5 || formData.action_type_id == 6 || formData.action_type_id == 8}
                    type={'input'}
                    name={'link'}
                    value={formData.link}
                    setValue={handler}
                />
                <FormField
                    title={'Количество очков сообщества'}
                    type={'inputNumber'}
                    name={'amount'}
                    value={formData.amount}
                    setValue={handler}
                    min={0}
                    max={10000}
                />
                <FormField
                    title={'Изображение действия'}
                    type={'file'}
                    name={'banner'}
                    value={formData.banner}
                    imagePreview={imagePreview}
                    setValue={handler}
                />
                <FormField
                    title={'Тип отображения'}
                    type={'segmentControl'}
                    name={'show_type'}
                    value={formData.show_type}
                    setValue={handler}
                    options={[
                        {
                            'label': 'Бустер',
                            'value': 'booster',
                            'aria-label': 'Бустер',
                        },
                        {
                            'label': 'Сообщество',
                            'value': 'group',
                            'aria-label': 'Сообщество',
                        },
                    ]}
                    helpText={[
                        'Если выбрать бустер, то действие будет доступно только для прохождения в бустере.',
                        'Если ввыбрать сообщество, то только на странице сообщества.'
                    ]}
                />
            </Form>
        </Group>
    )
}