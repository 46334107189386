import {Button, Image, ModalCard} from "@vkontakte/vkui";

export const GiftCodeFaqModalCard = ({id, setFaqActiveModal}) => {

    const changeActiveModal = () => {
        localStorage.setItem('faqIsCompleted', 1);
        setFaqActiveModal(null);
    }

    return (
        <ModalCard
            id={id}
            onClose={() => changeActiveModal()}
            header="Коды подарков"
            subheader="После покупки подарка приложение сгенерирует промокод, который можно активировать у сотрудника сообщества."
            actions={
                <Button
                    size="l"
                    mode="primary"
                    stretched
                    onClick={() => changeActiveModal()}
                >
                    Завершить
                </Button>
            }
        >
            <Image keepAspectRatio src={'https://sun9-16.userapi.com/impg/cl72tDEs-q0Ur2WdHoSQmxjIGIota5EYfSJnCw/TaGUCDitiG8.jpg?size=404x370&quality=95&sign=b527ab91bfc6c157941c4c07b00021e6&type=album'} widthSize="100%" />
        </ModalCard>
    )
}