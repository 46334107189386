import {Div, Text} from "@vkontakte/vkui";
import {Form} from "../form/Form";
import * as React from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import ActionService from "../../services/action/ActionService";
import store from "../../store";
import {addAppMessage} from "../../features/errors/appMessagesSlice";

export const VkSetLikeOnLastPostsActionForm = ({setPopout, setActionIsCompleted}) => {
    const actionService = new ActionService(setPopout);

    const routeNavigator = useRouteNavigator();

    const [loading, setLoading] = React.useState(false);
    const params = useParams();
    const queryParams = new URLSearchParams(window.location.search);

    const onSubmitHandler = async() => {
        try {
            await actionService.execute({'action_uuid': params.uuid, booster_uuid: queryParams.has('booster_uuid') ? queryParams.get('booster_uuid') : null}).then(res => {
                setActionIsCompleted();
                store.dispatch(addAppMessage({message: 'Действие успешно выполнено', subtitle: 'Баллы начислятся в течении нескольких минут.'}));
                routeNavigator.hideModal()
                setLoading(false)
            })
        } catch (exception) {
            setLoading(false)
        }
    }

    return (
        <Form loading={loading} onSubmit={onSubmitHandler} submitButtonText={'Поставить'}>
            <Div style={{padding: 16}}>
                <Text>
                    После нажатия кнопки вы поставите лайки на последние 5 постов в сообществе Вконтакте.
                </Text>
            </Div>
        </Form>
    )
}