import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import {CellButton, Group, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import {Icon28AddOutline} from "@vkontakte/icons";
import React from "react";
import {AdminLoyaltyLevelsList} from "../../../components/loyaltyLevels/AdminLoyaltyLevelsList";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

const AdminLoyaltyLevelsListPanel = ({...props}) => {
    const routeNavigator = useRouteNavigator();
    const params = useParams();

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
                after={<VkUser user={props.user} mode={'plain'} />}
            >
                Уровни лояльности
            </PanelHeader>
            <Group>
                <CellButton onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/loyalty/levels/create')} before={<Icon28AddOutline/>}>
                    Добавить
                </CellButton>
            </Group>
            <AdminLoyaltyLevelsList />
        </CustomPanel>
    )
}

export default AdminLoyaltyLevelsListPanel;