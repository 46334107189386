import { createSlice } from '@reduxjs/toolkit'

export const adminLoyaltyLevelsSlice = createSlice({
    name: 'adminLoyaltyLevelsSlice',
    initialState: {
        list: [],
    },
    reducers: {
        setAdminLoyaltyLevelsInStore: (state, action) => {
            state.list = action.payload;
        },
        addAdminLoyaltyLevelsInStore: (state, action) => {
            state.list.unshift(action.payload);
        },
        updateLoyaltyLevelsInStore(state, action) {
            state.list = state.list.map(item => {
                if (item.uuid === action.payload.id) {
                    return {
                        ...item,
                        ...action.payload
                    };
                }
                return item;
            });
        },
        deleteLoyaltyLevelsInStore(state, action) {
            state.list = state.list.filter(item => {
                return item.uuid !== action.payload;
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setAdminLoyaltyLevelsInStore,
    addAdminLoyaltyLevelsInStore,
    updateLoyaltyLevelsInStore,
    deleteLoyaltyLevelsInStore
} = adminLoyaltyLevelsSlice.actions

export default adminLoyaltyLevelsSlice.reducer