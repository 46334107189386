import {Avatar, Flex, Group, Image, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Separator, Tabs, TabsItem} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import VkGroupMemberService from "../../../services/vk/VkGroupMemberService";
import {CustomPanel} from "../../../components/vkUI/CustomPanel";

export const VkGroupMemberShowAllActionsPanel = ({...props}) => {
    const vkGroupMemberService = new VkGroupMemberService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [memberActions, setMemberActions] = React.useState([]);
    const [selectedActionType, setSelectedActionType] = React.useState('likes');

    const fetchData = async () => {
        await vkGroupMemberService.showAllActions(params.uuid).then(res => {
            setMemberActions(res)
            setFetching(false);
        })
    }

    const renderItem = (item, index) => {
        return (
            <RichCell
                before={<Avatar src={item.vkGroup.photo} />}
                multiline={true}
                actions={
                    item.data.attachments ?
                        <Flex align={'center'} justify={'start'}>
                            {
                                item.data.attachments.map(
                                    element => element.photo ?
                                        <Image
                                            src={element.photo.sizes[element.photo.sizes.length - 1].url}
                                            size={80}
                                            style={{margin: 5}}
                                        />
                                        : null
                                )
                            }
                        </Flex> : null
                }
                key={index}

                subhead={'Пост от ' + new Date(item.data.date * 1000).toLocaleDateString('ru-RU')}
                caption={item.comment_data ? 'Комментарий: ' + item.comment_data.text : ''}
                text={item.data.text}
            >
                {item.vkGroup.name}
            </RichCell>
        )
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomPanel id={props.id}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Все действия подписчика
            </PanelHeader>
            <Group>
                <Tabs>
                    <TabsItem
                        selected={selectedActionType === 'likes'}
                        onClick={() => {
                            setSelectedActionType('likes');
                        }}
                        id="tab-likes"
                        aria-controls="tab-content-likes"
                    >
                        Лайки
                    </TabsItem>
                    <TabsItem
                        selected={selectedActionType === 'comments'}
                        onClick={() => {
                            setSelectedActionType('comments');
                        }}
                        id="tab-comments"
                        aria-controls="tab-content-comments"
                    >
                        Комментарии
                    </TabsItem>
                    <TabsItem
                        selected={selectedActionType === 'reposts'}
                        onClick={() => {
                            setSelectedActionType('reposts');
                        }}
                        id="tab-likes"
                        aria-controls="tab-content-reposts"
                    >
                        Репосты
                    </TabsItem>
                </Tabs>
            </Group>
            {
                selectedActionType === 'likes' && !fetching ? <Group>
                    {
                        memberActions.likes.length ?  memberActions.likes.map((item, index) => (
                            <>
                                {renderItem(item, index)}
                                <Separator />
                            </>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : null
            }
            {
                selectedActionType === 'comments' && !fetching ? <Group>
                    {
                        memberActions.comments.length ? memberActions.comments.map((item, index) => (
                            <>
                                {renderItem(item, index)}
                                <Separator />
                            </>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : null
            }
            {
                selectedActionType === 'reposts' && !fetching ? <Group>
                    {
                        memberActions.reposts.length ? memberActions.reposts.map((item, index) => (
                            <>
                                {renderItem(item, index)}
                                <Separator />
                            </>
                        )) : <Placeholder size={'medium'} header={'Пока что тут ничего нет :c'} />
                    }
                </Group> : null
            }
        </CustomPanel>
    )
}