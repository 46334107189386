import {CellButton, Group, Header, Link, Separator, Spacing} from "@vkontakte/vkui";
import * as React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Icon28AddOutline} from "@vkontakte/icons";

export const GroupContent = ({children, description, header, showAllLink, addLink, action, separator}) => {
    const routeNavigator = useRouteNavigator();
    return (
        <Group
            description={description}
            header={
            <Header aside={showAllLink ?
                <Link onClick={() => routeNavigator.push(showAllLink)}>
                    Показать все
                </Link> : action}>
                {header}
            </Header>
        }>
            {
                separator && <Spacing size={24}>
                    <Separator />
                </Spacing>
            }
            {children}
            {
                separator && <Spacing size={24}>
                    <Separator />
                </Spacing>
            }
            {
                addLink ? <CellButton onClick={() => routeNavigator.push(addLink)} before={<Icon28AddOutline/>}>
                    Добавить
                </CellButton> : null
            }
        </Group>
    )
}