import React, {useState} from "react";
import {CellButton, Group, Panel, PanelHeader, PanelHeaderBack, Tabs, TabsItem} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {AdminGiftsList} from "../../../components/gift/AdminGiftsList";
import {Icon28AddOutline} from "@vkontakte/icons";
import {AdminGiftsArchive} from "../../../components/gift/AdminGiftsArchive";

export const AdminGiftsListPanel = ({...props}) => {
    const [selectedTab, setSelectedTab] = useState('list');

    const routeNavigator = useRouteNavigator();

    const params = useParams();

    return (
        <Panel>
            <PanelHeader before={<PanelHeaderBack onClick={() => routeNavigator.back()} />} after={<VkUser user={props.user} mode={'plain'} />}>
                Подарки
            </PanelHeader>
            <Tabs style={{marginBottom: 15}}>
                <TabsItem
                    selected={selectedTab === 'list'}
                    onClick={() => setSelectedTab('list')}
                >
                    Список
                </TabsItem>
                <TabsItem
                    selected={selectedTab === 'archive'}
                    onClick={() => setSelectedTab('archive')}
                >
                    Архив
                </TabsItem>
            </Tabs>
            {
                selectedTab === 'list' && <>
                    <Group>
                        <CellButton onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/gifts/create')} before={<Icon28AddOutline/>}>
                            Добавить подарок
                        </CellButton>
                    </Group>
                    <AdminGiftsList groupId={params.groupId} addLink={false} showAllLink={false} />
                </>
            }
            {
                selectedTab === 'archive' && <AdminGiftsArchive groupId={params.groupId} />
            }
        </Panel>
    )
}