import {Epic, SplitCol, Tabbar, TabbarItem, View} from "@vkontakte/vkui";
import {AuthPanel} from "../../../panels/Auth/AuthPanel";
import {ProfilePanel} from "../../../panels/Profile/ProfilePanel";
import {BoosterShowPanel} from "../../../panels/Booster/BoosterShowPanel";
import {GiftPanel} from "../../../panels/Gift/GiftPanel";
import * as React from "react";
import {Icon28Settings} from "@vkontakte/icons";
import {AdminPanel} from "../../../panels/Admin/AdminPanel";
import {AdminBoosterListPanel} from "../../../panels/Admin/Boosters/AdminBoosterListPanel";
import {AdminCheckGiftPanel} from "../../../panels/Admin/Gifts/AdminCheckGiftPanel";
import {AdminVkUsersPanel} from "../../../panels/Admin/VkUser/AdminVkUsersPanel";
import {AdminVkUsersActionsPanel} from "../../../panels/Admin/VkUser/AdminVkUsersActionsPanel";
import {VkGroupsListPanel} from "../../../panels/Vk/VkGroupsListPanel";
import {VkGroupShowPanel} from "../../../panels/Vk/VkGroupShowPanel";
import {AdminActionsListPanel} from "../../../panels/Admin/Actions/AdminActionsListPanel";
import {AdminGiftsListPanel} from "../../../panels/Admin/Gifts/AdminGiftsListPanel";
import {AdminStatPanel} from "../../../panels/Admin/Stat/AdminStatPanel";
import {AdminStatGroupPanel} from "../../../panels/Admin/Stat/AdminStatGroupPanel";
import {VkGroupShowReviewsPanel} from "../../../panels/Vk/VkGroupShowReviewsPanel";
import {AdminVkGroupShowPanel} from "../../../panels/Admin/VkGroup/AdminVkGroupShowPanel";
import AdminLoyaltyLevelsListPanel from "../../../panels/Admin/LoyaltyLevels/AdminLoyaltyLevelsListPanel";
import {VkGroupMemberShowPanel} from "../../../panels/Vk/Members/VkGroupMemberShowPanel";
import {VkGroupMemberShowActionsPanel} from "../../../panels/Vk/Members/VkGroupMemberShowActionsPanel";
import {VkGroupMemberShowGiftsPanel} from "../../../panels/Vk/Members/VkGroupMemberShowGiftsPanel";
import {VkGroupMembersTopListPanel} from "../../../panels/Vk/Members/VkGroupMembersTopListPanel";
import {VkGroupMemberShowAllActionsPanel} from "../../../panels/Vk/Members/VkGroupMemberShowAllActionsPanel";
import {AdminVkGroupStoriesCreatePanel} from "../../../panels/Vk/Stories/AdminVkGroupStoriesCreatePanel";
import {VkGroupMaterialsPanel} from "../../../panels/Vk/Materials/VkGroupMaterialsPanel";
import {AdminVkGroupCardPanel} from "../../../panels/Vk/Card/AdminVkGroupCardPanel";

export const MobileLayout = (
    {routeNavigator, activeStory, viewWidth, menuItems, setPopout, clearPopout,
        setActiveModal, user, activePanel, subscription, openError, snackbar}) => {

    return (
        <SplitCol width="100%" maxWidth="768px" stretchedOnMobile autoSpaced>
            <Epic activeStory={user ? activeStory : 'auth'}
                  tabbar={
                      user && viewWidth.tabletMinus && (
                          <Tabbar className={viewWidth.tabletMinus.className}>
                              {
                                  menuItems.map((item, index) => (
                                      <TabbarItem
                                          key={index}
                                          onClick={() => routeNavigator.push(item.route)}
                                          selected={activeStory === item.name}
                                          data-story={item.name}
                                          text={item.title}
                                          indicator={item.indicator}
                                      >
                                          {item.icon}
                                      </TabbarItem>
                                  ))
                              }
                              {
                                  subscription && subscription.status === 'active' ?
                                      <TabbarItem
                                          onClick={() => routeNavigator.push('/admin')}
                                          selected={activeStory === 'admin'}
                                          data-story={'admin'}
                                          text={'Панель управления'}
                                      >
                                          <Icon28Settings />
                                      </TabbarItem> : null
                              }
                          </Tabbar>
                      )
                  }
            >
                <View id="auth" activePanel="auth">
                    <AuthPanel id={'auth'} setPopout={setPopout} clearPopout={clearPopout} />
                </View>
                <View id="profile" activePanel="profile">
                    <ProfilePanel
                        id={'profile'}
                        routeNavigator={routeNavigator}
                        subscription={subscription}
                        setActiveModal={setActiveModal}
                        user={user}
                    />
                </View>
                <View id={'groups'} activePanel={activePanel ?? 'vkGroupsListPanel'}>
                    <VkGroupsListPanel
                        id={'vkGroupsListPanel'}
                        routeNavigator={routeNavigator}
                        openError={openError}
                        snackbar={snackbar}
                        user={user}
                    />
                    <VkGroupShowPanel
                        id={'vkGroupShowPanel'}
                        routeNavigator={routeNavigator}
                        openError={openError}
                        snackbar={snackbar}
                        user={user}
                    />
                    <VkGroupShowReviewsPanel
                        id={'vkGroupReviewShowPanel'}
                        routeNavigator={routeNavigator}
                        openError={openError}
                        snackbar={snackbar}
                        setPopout={setPopout}
                        user={user}
                    />
                    <VkGroupMembersTopListPanel
                        id={'vkGroupMembersTopListPanel'}
                        routeNavigator={routeNavigator}
                        openError={openError}
                        snackbar={snackbar}
                        setPopout={setPopout}
                        user={user}
                    />
                </View>
                <View id="booster" activePanel={activePanel ?? 'boosterListPanel'}>
                    <BoosterShowPanel
                        id={'boosterShowPanel'}
                        routeNavigator={routeNavigator}
                        user={user}
                        openError={openError}
                    />
                </View>
                <View id="gift" activePanel="gift">
                    <GiftPanel
                        id={'gift'}
                        routeNavigator={routeNavigator}
                        user={user}
                    />
                </View>
                <View id="admin" activePanel={activePanel ?? 'admin'}>
                    <AdminPanel
                        id={'admin'}
                        routeNavigator={routeNavigator}
                        user={user}
                        subscription={subscription}
                        snackbar={snackbar}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminActionsListPanel
                        id={'adminActionsListPanel'}
                        user={user}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminGiftsListPanel
                        id={'adminGiftsListPanel'}
                        user={user}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminBoosterListPanel
                        id={'adminBoosterListPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminCheckGiftPanel
                        id={'adminCheckGiftPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminVkUsersPanel
                        id={'adminVkUsersPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminVkUsersActionsPanel
                        id={'adminVkUsersActionsPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <VkGroupMemberShowPanel
                        id={'adminVkGroupMemberShowPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <VkGroupMemberShowActionsPanel
                        id={'adminVkGroupMemberShowActionsPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <VkGroupMemberShowAllActionsPanel
                        id={'adminVkGroupMemberShowAllActionsPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <VkGroupMemberShowGiftsPanel
                        id={'adminVkGroupMemberShowGiftsPanel'}
                        routeNavigator={routeNavigator}
                        setPopout={setPopout}
                        clearPopout={clearPopout}
                        openError={openError}
                    />
                    <AdminStatPanel
                        id={'adminStatPanel'}
                        setPopout={setPopout}
                    />
                    <AdminStatGroupPanel
                        id={'adminStatGroupPanel'}
                        setPopout={setPopout}
                    />
                    <AdminVkGroupShowPanel
                        id={'adminVkGroupShowPanel'}
                        setPopout={setPopout}
                    />
                    <AdminLoyaltyLevelsListPanel
                        id={'adminLoyaltyLevelsListPanel'}
                        setPopout={setPopout}
                        user={user}
                    />
                    <AdminVkGroupStoriesCreatePanel
                        id={'adminVkGroupStoryPanel'}
                        setPopout={setPopout}
                        user={user}
                    />
                    <VkGroupMaterialsPanel
                        id={'adminVkGroupMaterialsPanel'}
                        setPopout={setPopout}
                        user={user}
                    />
                    <AdminVkGroupCardPanel
                        id={'adminVkGroupCardPanel'}
                        setPopout={setPopout}
                        user={user}
                    />
                </View>
            </Epic>
        </SplitCol>
    )
}