import {Avatar, Button, ButtonGroup, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Delete, Icon12Pencil, Icon12Switch, Icon56UsersOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import store from "../../store";
import {useSelector} from "react-redux";
import GiftService from "../../services/gift/GiftService";
import {deleteAdminGiftInStore, setAdminGiftsInStore} from "../../features/gifts/adminGiftsSlice";
import {DeleteButton} from "../buttons/DeleteButton";

export const AdminGiftsArchive = ({limit, groupId, showAllLink, addLink}) => {
    const giftService = new GiftService();

    const [fetching, setFetching] = React.useState(true);
    const [restoreLoading, setRestoreLoading] = React.useState(false);

    const [gifts, setGifts] = React.useState([]);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        await giftService.deleted({vk_group_id: groupId}).then(res => {
            setGifts(giftService.list);
            setFetching(false);
        });
    }

    const onClickRestoreGift = async (gift) => {
        setRestoreLoading(true)
        await giftService.restore(gift.uuid).then(res => {
            fetchPanelData().then(res => {
                setRestoreLoading(false)
            });
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Подарки'}>
            {
                !fetching ?
                    gifts.length ?
                        gifts.map((gift, index) => (
                            <GroupContentList
                                key={index}
                                before={<Avatar size={48} src={gift.vkGroup ? gift.vkGroup.photo : null}/>}
                                subhead={'Удален ' + gift.deleted_at}
                                content={gift.name}
                                text={gift.vkGroup ? gift.vkGroup.name : null}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12Pencil />} onClick={() => routeNavigator.push('/admin/groups/' + groupId + '/actions/update/' + gift.uuid)} mode="primary" />
                                        <Button before={<Icon12Switch />} title={'Восстановить'} loading={restoreLoading} onClick={() => onClickRestoreGift(gift)} mode="primary" />
                                    </ButtonGroup>
                                }/>
                        )) : <Placeholder icon={<Icon56UsersOutline/>}>
                            Пока еще нет подарков
                        </Placeholder> : <Spinner size={'medium'}/>
            }
        </GroupContent>
    )
}