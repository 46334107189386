import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class VkGroupMembersStatService extends Service {
    basePath = 'stat/vk-group/members'

    getActivityStat = async (params) => {
        return axios.get(`${this.basePath}/activity-stat`, {
            params: params,
        })
    }
}

export default VkGroupMembersStatService;