import {Avatar, Button, ButtonGroup, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Delete, Icon12Pencil, Icon36DiscountOutline, Icon56UsersOutline} from "@vkontakte/icons";
import ActionService from "../../services/action/ActionService";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import store from "../../store";
import {useSelector} from "react-redux";
import LoyaltyLevelService from "../../services/loyaltyLevels/LoyaltyLevelService";
import {deleteLoyaltyLevelsInStore, setAdminLoyaltyLevelsInStore} from "../../features/loyaltyLevels/adminLoyaltyLevelsSlice";
import {DeleteButton} from "../buttons/DeleteButton";

export const AdminLoyaltyLevelsList = ({limit, groupId, showAllLink, addLink}) => {
    const loyaltyLevelsService = new LoyaltyLevelService();

    const [fetching, setFetching] = React.useState(true);
    const [deleteLoading, setDeleteLoading] = React.useState(false);

    const items = useSelector(state => state.adminLoyaltyLevels.list);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        await loyaltyLevelsService.all({limit: limit, vk_group_id: groupId}).then(res => {
            store.dispatch(setAdminLoyaltyLevelsInStore(loyaltyLevelsService.list));
            setFetching(false);
        });
    }

    const onClickDeleteHandler = async (item) => {
        setDeleteLoading(true);
        await loyaltyLevelsService.delete(item.uuid).then(res => {
            store.dispatch(deleteLoyaltyLevelsInStore(item.uuid));
            setDeleteLoading(false);
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Уровни лояльности'} description={'Уровни лояльности позволяют разделять клиентов по количеству выполненных действий.'} showAllLink={showAllLink ? '/admin/groups/' + groupId + '/loyalty/levels' : null} addLink={addLink ? '/admin/groups/' + groupId + '/loyalty/levels/create' : null}>
            {
                !fetching ?
                    items.length ?
                        items.map((item, index) => (
                            <GroupContentList
                                key={index}
                                content={item.title}
                                before={item.vkGroup ? <Avatar size={40} src={item.vkGroup.photo} /> : null}
                                caption={item.description}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12Pencil />} onClick={() => routeNavigator.push('/admin/groups/' + groupId + '/loyalty/levels/update/' + item.uuid)} mode="primary" />
                                        <DeleteButton loading={deleteLoading} onClick={() => onClickDeleteHandler(item)} />
                                    </ButtonGroup>
                                }
                            />
                        )) :  <Placeholder icon={<Icon36DiscountOutline />}>
                            Вы еще не добавили уровни лояльности
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}