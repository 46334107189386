import {Button, ButtonGroup, ContentBadge, Flex, Group, Header, Placeholder, RichCell, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon16Comment, Icon16Like, Icon16Repost, Icon16View, Icon56StatisticsOutline} from "@vkontakte/icons";

const ActionStat = ({stat, loading, period, onChangePeriod}) => {
    return (
        <Group
            header={
                <Header aside={<ButtonGroup mode="horizontal" gap="m">
                                <Button
                                    onClick={() => onChangePeriod('daily')}
                                    appearance={period === 'daily' ? 'accent' : 'neutral'}
                                    mode={period === 'daily' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Сутки
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('weekly')}
                                    appearance={period === 'weekly' ? 'accent' : 'neutral'}
                                    mode={period === 'weekly' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Неделя
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('monthly')}
                                    appearance={period === 'monthly' ? 'accent' : 'neutral'}
                                    mode={period === 'monthly' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Месяц
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('all')}
                                    appearance={period === 'all' ? 'accent' : 'neutral'}
                                    mode={period === 'all' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Все время
                                </Button>
                            </ButtonGroup>}>
                    Действия
                </Header>
            }
        >
            {
                !loading ?
                    stat.length ?
                        stat.map((element, index) => (
                            <RichCell
                                key={index}
                                subhead={
                                    <small>
                                        <a href={element.link} target={'_blank'} style={{textDecoration: "none"}}>ссылка на пост</a>
                                    </small>
                                }
                                bottom={
                                    <Flex align={'center'} justify={'start'} gap={'s'}>
                                        <ContentBadge size={'m'} appearance={element.likes > 0 ? 'accent-green' : element.likes === 0 ? 'neutral' : 'accent-red'} mode={'outline'} capsule={false}>
                                            <ContentBadge.SlotIcon>
                                                <Icon16Like />
                                            </ContentBadge.SlotIcon>
                                            {element.likes}
                                        </ContentBadge>
                                        <ContentBadge size={'m'} appearance={element.comments > 0 ? 'accent-green' : element.comments === 0 ? 'neutral' : 'accent-red'} mode={'outline'} capsule={false}>
                                            <ContentBadge.SlotIcon>
                                                <Icon16Comment />
                                            </ContentBadge.SlotIcon>
                                            {element.comments}
                                        </ContentBadge>
                                        <ContentBadge size={'m'} appearance={element.reposts > 0 ? 'accent-green' : element.reposts === 0 ? 'neutral' : 'accent-red'} mode={'outline'} capsule={false}>
                                            <ContentBadge.SlotIcon>
                                                <Icon16Repost />
                                            </ContentBadge.SlotIcon>
                                            {element.reposts}
                                        </ContentBadge>
                                        <ContentBadge size={'m'} appearance={element.views > 0 ? 'accent-green' : element.views === 0 ? 'neutral' : 'accent-red'} mode={'outline'} capsule={false}>
                                            <ContentBadge.SlotIcon>
                                                <Icon16View />
                                            </ContentBadge.SlotIcon>
                                            {element.views}
                                        </ContentBadge>
                                    </Flex>
                                }
                            >
                                {element.name}
                            </RichCell>
                        ))
                        // <table width="100%" style={{borderSpacing: 0, borderCollapse: 'collapse'}}>
                        //     <tbody>
                        //     {
                        //         stat.map((element, index) => (
                        //             <tr key={index} style={{height: '40px', borderBottom: '1px solid #dce1e6'}}>
                        //                 <td align={'left'} style={{fontSize: '12px', paddingLeft: 10}}>
                        //                     <div>{element.name}</div>
                        //                     <small>
                        //                         <a href={element.link} target={'_blank'} style={{textDecoration: "none"}}>ссылка на пост</a>
                        //                     </small>
                        //                 </td>
                        //                 <td align={'center'}>
                        //                     {
                        //                         element.likes > 0 ? <span style={{color: 'lightgreen', fontSize: '12px'}}>+{element.likes}</span> :
                        //                             element.likes === 0 ? <span style={{fontSize: '12px'}}>{element.likes}</span> :
                        //                                 <span style={{color: 'tomato', fontSize: '12px'}}>{element.likes}</span>
                        //                     }
                        //                 </td>
                        //                 <td align={'center'}>
                        //                     {
                        //                         element.comments > 0 ? <span style={{color: 'lightgreen', fontSize: '12px'}}>+{element.comments}</span> :
                        //                             element.comments === 0 ? <span style={{fontSize: '12px'}}>{element.comments}</span> :
                        //                                 <span style={{color: 'tomato', fontSize: '12px'}}>{element.comments}</span>
                        //                     }
                        //                 </td>
                        //                 <td align={'center'}>
                        //                     {
                        //                         element.reposts > 0 ? <span style={{color: 'lightgreen', fontSize: '12px'}}>+{element.reposts}</span> :
                        //                             element.reposts === 0 ? <span style={{fontSize: '12px'}}>{element.reposts}</span> :
                        //                                 <span style={{color: 'tomato', fontSize: '12px'}}>{element.reposts}</span>
                        //                     }
                        //                 </td>
                        //                 <td align={'center'}>
                        //                     {
                        //                         element.views > 0 ? <span style={{color: 'lightgreen', fontSize: '12px'}}>+{element.views}</span> :
                        //                             element.views === 0 ? <span style={{fontSize: '12px'}}>{element.views}</span> :
                        //                                 <span style={{color: 'tomato', fontSize: '12px'}}>{element.views}</span>
                        //                     }
                        //                 </td>
                        //             </tr>
                        //         ))
                        //     }
                        //     </tbody>
                        // </table>
                        : <Placeholder icon={<Icon56StatisticsOutline/>}>
                            Статистика по действиям появятся на следующий день после их создания.
                        </Placeholder>
                    : <Spinner size={'medium'}/>
            }
        </Group>
    )
}

export default ActionStat;