import * as React from "react";
import {GroupContent} from "../vkUI/GroupContent";
import VkAdminGroupService from "../../services/vk/VkAdminGroupService";
import VkApiGroupService from "../../services/vk/VkApiGroupService";
import {useEffect} from "react";
import {Avatar, Button, ButtonGroup, Placeholder, RichCell, Spinner} from "@vkontakte/vkui";
import {Icon12Add, Icon12View, Icon56SearchOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import store from "../../store";
import {addAppMessage} from "../../features/errors/appMessagesSlice";

export const AdminVkGroupsList = () => {
    const vkApiGroupService = new VkApiGroupService();
    const vkGroupService = new VkAdminGroupService();
    const [fetching, setFetching] = React.useState(true);
    const [items, setItems] = React.useState([]);

    const routeNavigator = useRouteNavigator();

    const fetchData = async () => {
        setFetching(true);
        await vkApiGroupService.allWithAdmin().then(res => {
            setItems(res.data.data);
            setFetching(false);
        });
    }

    const addGroup = async (groupId) => {
        try {
            await vkGroupService.add({group_id: groupId}).then(res => {
                store.dispatch(addAppMessage({message: 'Сообщество добавлено', subtitle: 'Теперь вы можете создавать в нем действия и подаркию'}));
                fetchData();
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    useEffect(() => {
       fetchData()
    }, [])

    return (
        <GroupContent
            header={'Сообщества'}
            description={'Сообщества в которых вы администратор.'}
            separator={false}
        >
            {
                !fetching ? items.length ? items.map((item, index) => (
                    <RichCell
                        key={index}
                        onClick={item.is_added ? () => routeNavigator.push('/admin/groups/show/' + item.id) : null}
                        before={item.photo_200 ? <Avatar size={40} src={item.photo_200} /> : null}
                        after={
                            <ButtonGroup>
                                {
                                    item.is_added ?
                                        <Button before={<Icon12View />} onClick={() => routeNavigator.push('/admin/groups/show/' + item.group_id)} mode="primary" />
                                    : <Button before={<Icon12Add />} onClick={() => addGroup(item.id)} mode="primary" />
                                }
                            </ButtonGroup>
                        }
                    >
                        {item.name}
                    </RichCell>
                )) :  <Placeholder icon={<Icon56SearchOutline />}>
                    Ничего не найдено
                </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}