import {Avatar, Div, Group, IconButton, Panel, PanelHeader, RichCell, SegmentedControl, Spinner, UsersStack} from "@vkontakte/vkui";
import {VkUser} from "../../components/VkUser";
import * as React from "react";
import {allClientsGroups} from "../../services/vk/VkClientGroupService";
import {useEffect, useState} from "react";
import {numToWord} from "../../helpers/StrHelper";
import {Icon16StarCircleFillYellow, Icon24Search} from "@vkontakte/icons";
import CityService from "../../services/city/CityService";
import {FormField} from "../../components/form/FormField";
import {CustomPagination} from "../../components/CustomPagination";

export const VkGroupsListPanel = ({...props}) => {
    const [groupsType, setGroupsType] = React.useState('all');
    const [groups, setGroups] = React.useState([]);
    const [fetching, setFetching] = React.useState(true);
    const [cities, setCities] = React.useState([]);
    const cityService = new CityService(props.setPopout);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationMeta, setPaginationMeta] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        // city_id: props.user.city_id
    });

    const fetchPanelData = async () => {
        await fetchGroups();
        if (!cities.length) {
            await cityService.all().then(res => {
                setCities(cityService.list)
            });
        }
    }

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const fetchGroups = async () => {
        setFetching(true)
        await allClientsGroups({filters: {groupType: groupsType, city_id: formData.city_id, name: formData.name}, page: currentPage}).then((response) => {
            setGroups(response.data);
            setPaginationMeta(response.meta);
            setFetching(false)
        })
    }

    const onFilter = async () => {
        await fetchGroups();
    }

    useEffect(() => {
        fetchPanelData();
    }, [groupsType, currentPage])

    return (
        <Panel id={'vkGroupsListPanel'}>
            <PanelHeader after={<VkUser user={props.user} mode={'plain'} />}>Сообщества</PanelHeader>
            <Group>
                <FormField
                    type="input"
                    name={'name'}
                    placeholder="Введите название сообщества"
                    value={formData.name}
                    setValue={handler}
                    after={
                        <IconButton style={{background: 'rgba(235, 242, 250, 0.99)', borderLeft: '1px solid rgba(0, 0, 0, 0.2)'}} hoverMode="opacity" label="Поиск" onClick={onFilter}>
                            <Icon24Search />
                        </IconButton>
                    }
                />
                {/*<FormItem style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 0, paddingTop: 0}}>*/}
                {/*    <FormField*/}
                {/*        type={'customSelect'}*/}
                {/*        placeholder="Введите название города"*/}
                {/*        searchable*/}
                {/*        options={cities}*/}
                {/*        name={'city_id'}*/}
                {/*        value={formData.city_id}*/}
                {/*        setValue={handler}*/}
                {/*    />*/}
                {/*    <Button*/}
                {/*        before={<Icon24Search />}*/}
                {/*        stretched={true}*/}
                {/*        appearance={'positive'}*/}
                {/*        size={'l'}*/}
                {/*        loading={loading}*/}
                {/*        onClick={() => onFilter()}*/}
                {/*    />*/}
                {/*</FormItem>*/}
                <Div>
                    <SegmentedControl
                        value={groupsType}
                        onChange={(value) => setGroupsType(value)}
                        options={[
                            {
                                'label': 'Все сообщества',
                                'value': 'all',
                                'aria-label': 'Все сообщества',
                            },
                            {
                                'label': 'Мои сообщества',
                                'value': 'my',
                                'aria-label': 'Мои сообщества',
                            },
                        ]}
                    />
                </Div>
                {
                    !fetching ?
                        groups.length ?
                            <Div>
                                {
                                    groups.map((group, index) => (
                                        <RichCell
                                            key={index}
                                            onClick={() => props.routeNavigator.push('/groups/' + group.group_id)}
                                            subhead={group.city}
                                            before={<Avatar size={40} src={group.photo}/>}
                                            caption={group.description}
                                            after={<span style={{display: 'flex', alignItems: 'center', paddingLeft: 5}}><Icon16StarCircleFillYellow style={{paddingRight: 2}} /> {group.rating}</span>}
                                            bottom={
                                                group.userFriendsWithSubscribe.length ?
                                                    <UsersStack photos={group.userFriendsWithSubscribe}>
                                                        {group.userFriendsWithSubscribe.length} {numToWord(group.userFriendsWithSubscribe.length , ['общий', 'общих', 'общих'])} {numToWord(group.userFriendsWithSubscribe.length , ['друг', 'друга', 'друзей'])}
                                                    </UsersStack>
                                                    : null
                                            }
                                            multiline
                                        >
                                            {group.name}
                                        </RichCell>
                                    ))
                                }
                                <CustomPagination currentPage={currentPage} paginationMeta={paginationMeta} onPageChange={onPageChange} />
                            </Div>
                    : <Div style={{textAlign: 'center'}}>
                                Сообщества в вашем городе не найдены, попробуйте выбрать другой город. Или воспользуйтесь фильтром - Мои сообщества.
                    </Div>
                        : <Spinner size={'medium'} />
                }
            </Group>
        </Panel>
    )
}