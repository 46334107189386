import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class ActionService extends Service {
    basePath = 'action';

    execute = async (params) => {
        try {
            const response = await axios.post(this.basePath + '/execute', params);
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}

export default ActionService;