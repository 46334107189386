import Service from "../Service";
import axios from "../../plugins/axios-plugin";

export default class VkGroupMemberService extends Service {
    basePath = 'vk/admin/groups/members';

    showActions = async (uuid) => {
        try {
            const response = await axios.get(this.basePath + '/' + uuid + '/actions');
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };

    showAllActions = async (uuid) => {
        try {
            const response = await axios.get(this.basePath + '/' + uuid + '/actions/all');
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };

    showGifts = async (uuid) => {
        try {
            const response = await axios.get(this.basePath + '/' + uuid + '/gifts');
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };

    showTopList = async (params) => {
        try {
            const response = await axios.get(this.basePath + '/top', {
                params: params
            });
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}