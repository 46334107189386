import {Flex, Pagination} from "@vkontakte/vkui";
import * as React from "react";

export const CustomPagination = ({currentPage, paginationMeta, onPageChange}) => {
    return (
        paginationMeta && <Flex align="center" justify="center" style={{ marginTop: '10px' }}>
            <Pagination
                navigationButtonsStyle={'icon'}
                currentPage={currentPage}
                siblingCount={0}
                boundaryCount={1}
                totalPages={paginationMeta.last_page}
                onChange={(page) => onPageChange(page)}
            />
        </Flex>
    )
}