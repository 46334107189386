import {Group, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderClose, useAdaptivityConditionalRender, usePlatform} from "@vkontakte/vkui";
import {Icon24Dismiss} from "@vkontakte/icons";
import * as React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import {useSelector} from "react-redux";

export const CustomModal = ({children, id, headerText}) => {
    const routeNavigator = useRouteNavigator();
    const platform = usePlatform();
    const {sizeX} = useAdaptivityConditionalRender();
    const appError = useSelector((state) => state.appErrors.error)

    useEffect(() => {
        if (appError) {
            routeNavigator.hideModal();
        }
    }, [appError])

    return (
        <ModalPage
            id={id}
            onClose={() => routeNavigator.back()}
            hideCloseButton={platform === 'ios'}
            settlingHeight={100}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === 'android' && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={() => routeNavigator.back()} />
                        )
                    }
                    after={
                        platform === 'ios' && (
                            <PanelHeaderButton onClick={() => routeNavigator.back()}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    {headerText}
                </ModalPageHeader>
            }
        >
            <Group>
                {children}
            </Group>
        </ModalPage>
    )
}