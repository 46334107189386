import {CellButton, Group, Panel, PanelHeader, PanelHeaderBack, Tabs, TabsItem} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import {AdminActionsList} from "../../../components/action/AdminActionsList";
import {Icon28AddOutline} from "@vkontakte/icons";
import React, {useState} from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {AdminActionsArchive} from "../../../components/action/AdminActionsArchive";

export const AdminActionsListPanel = ({...props}) => {
    const [selectedTab, setSelectedTab] = useState('list');

    const routeNavigator = useRouteNavigator();
    const params = useParams();

    return (
        <Panel>
            <PanelHeader
                before={<PanelHeaderBack onClick={() => props.routeNavigator.back()} />}
                after={<VkUser user={props.user} mode={'plain'} />}
            >
                Действия
            </PanelHeader>
            <Tabs style={{marginBottom: 15}}>
                <TabsItem
                    selected={selectedTab === 'list'}
                    onClick={() => setSelectedTab('list')}
                >
                    Список
                </TabsItem>
                <TabsItem
                    selected={selectedTab === 'archive'}
                    onClick={() => setSelectedTab('archive')}
                >
                    Архив
                </TabsItem>
            </Tabs>
            {
                selectedTab === 'list' && <>
                    <Group>
                        <CellButton onClick={() => routeNavigator.push('/admin/groups/' + params.groupId + '/actions/create')} before={<Icon28AddOutline/>}>
                            Добавить действие
                        </CellButton>
                    </Group>
                    <AdminActionsList groupId={params.groupId} addLink={true} />
                </>
            }
            {
                selectedTab === 'archive' && <>
                    <AdminActionsArchive groupId={params.groupId} addLink={true} />
                </>
            }
        </Panel>
    )
}