import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {LoyaltyLevelForm} from "../../components/loyaltyLevels/LoyaltyLevelForm";

export const LoyaltyLevelUpdateModal = ({...props}) => {

    return (
        <CustomModal id={'loyalty_level_update_modal'} headerText={'Редактировать уровень лояльности'}>
            <LoyaltyLevelForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}