import {Group, Placeholder} from "@vkontakte/vkui";
import {Icon56UsersOutline} from "@vkontakte/icons";
import * as React from "react";

export const AuthPlaceholder = () => {
    return (
        <Group>
            <Placeholder
                icon={<Icon56UsersOutline/>}
                header="Войдите с помощью Вконтакте"
                action={null}
            >
                Авторизуйтесь, чтобы получить доступ ко всем функциям Loyalty Booster.
            </Placeholder>
            <div style={{margin: '0 20px 50px'}}>
                <div id="VkIdSdkOneTap"/>
            </div>
        </Group>
    )
}