import {CellButton, Group, Header, InfoRow, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderClose, SimpleCell, Spinner} from "@vkontakte/vkui";
import {Icon24Dismiss, Icon28GifOutline, Icon28Services} from "@vkontakte/icons";
import * as React from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import VkGroupMemberService from "../../services/vk/VkGroupMemberService";

export const ClientShowModal = ({...props}) => {
    const vkGroupMemberService = new VkGroupMemberService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const [user, setUser] = React.useState({});

    const fetchData = async () => {
        await vkGroupMemberService.show(params.id).then(res => {
            setUser(vkGroupMemberService.model)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <ModalPage
            id={'client_show_modal'}
            onClose={() => props.routeNavigator.back()}
            hideCloseButton={props.platform === 'ios'}
            settlingHeight={100}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    before={
                        props.sizeX.compact &&
                        props.platform === 'android' && (
                            <PanelHeaderClose className={props.sizeX.compact.className} onClick={() => props.routeNavigator.back()} />
                        )
                    }
                    after={
                        props.platform === 'ios' && (
                            <PanelHeaderButton onClick={() => props.routeNavigator.back()}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    Просмотр подписчика
                </ModalPageHeader>
            }
        >
            {
                !fetching ? <Group>
                    <Group header={<Header mode="secondary">Информация о пользователе</Header>}>
                        <SimpleCell>
                            <InfoRow header="Имя">{user.first_name + ' ' + user.last_name}</InfoRow>
                        </SimpleCell>
                        <SimpleCell multiline>
                            <InfoRow header="Дата рождения">{user.birth_date ?? 'Не указана'}</InfoRow>
                        </SimpleCell>
                        <SimpleCell>
                            <InfoRow header="Город">{user.city ?? 'Не указан'}</InfoRow>
                        </SimpleCell>
                        <SimpleCell>
                            <InfoRow header="Телефон">{user.phone ?? 'Не указан'}</InfoRow>
                        </SimpleCell>
                        <SimpleCell>
                            <InfoRow header="VK ID">{user.vk_id}</InfoRow>
                        </SimpleCell>
                        {
                            user.screen_name && <SimpleCell>
                                <InfoRow header="Ссылка на профиль Вконтакте">
                                    <a href={'https://vk.com/' + user.screen_name} target={'_blank'}>
                                        https://vk.com/{user.screen_name}
                                    </a>
                                </InfoRow>
                            </SimpleCell>
                        }
                        <CellButton onClick={() => props.routeNavigator.push('/admin/groups/' + params.groupId + '/members/show/' + params.uuid + '/actions')} before={<Icon28Services />}>Выполненные действия</CellButton>
                        <CellButton onClick={() => props.routeNavigator.push('/admin/groups/' + params.groupId + 'members/show/' + params.uuid + '/gifts')} before={<Icon28GifOutline />}>Полученные подарки</CellButton>
                    </Group>
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </ModalPage>
    )
}
