import {checkErrors} from "../../helpers/AppHelper";
import {Avatar, ChipsSelect, CustomSelect, CustomSelectOption, Div, File, FormItem, Image, Input, SegmentedControl, Select, SimpleCell, Switch, Textarea} from "@vkontakte/vkui";
import * as React from "react";
import {Icon24Document, Icon24LogoVkColor} from "@vkontakte/icons";
import {useSelector} from "react-redux";

export const FormField = (
    {
        title, type, name, loading, value, setValue, options, optionLabel, optionId, optionImage, optionText, visible,
        min, max, helpText, imagePreview, placeholder, after
    }
) => {
    const formErrors = useSelector((state) => state.formErrors.list)
    const handleWheel = (event) => {
        event.preventDefault();
    };

    return (
        visible !== false ?
            <FormItem
                top={title}
                htmlFor={name}
                style={{ flexGrow: 1, flexShrink: 1 }}
                status={checkErrors(formErrors, name) ? 'error' : 'valid'}
                bottom={
                    checkErrors(formErrors, name) ? formErrors[name] : null
                }
            >
                {
                    type === 'input' ?
                        <Input
                            value={value}
                            onChange={(event) => setValue(name, event.currentTarget.value)}
                            disabled={loading}
                            id={name}
                            placeholder={placeholder}
                            after={after}
                        /> :
                    type === 'inputNumber' ?
                        <Input
                            value={value}
                            onFocus={(event) => event.target.onwheel = handleWheel}
                            onBlur={(event) => event.target.onwheel = handleWheel}
                            onChange={(event) => setValue(name, event.currentTarget.value)}
                            disabled={loading}
                            type={'number'}
                            min={min}
                            max={max}
                            id={name}
                        /> :
                        type === 'select' && options ?
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Select
                                    id={'select_' + name}
                                    placeholder="Не выбрано"
                                    value={value}
                                    searchable={true}
                                    onChange={(event) => setValue(name, event.currentTarget.value)}
                                    options={options.map((item) => ({
                                        label: item[optionLabel],
                                        value: item[optionId],
                                        avatar: item[optionImage],
                                    }))}
                                    renderOption={({option, ...restProps}) => (
                                        <CustomSelectOption
                                            {...restProps}
                                            key={option.value}
                                            before={option.avatar ? <Avatar size={24} src={option.avatar}/> : null}
                                        />
                                    )}
                                />
                            </div> :
                            type === 'customSelect' && options  ?
                                <CustomSelect
                                    id={name + '-select-id'}
                                    placeholder={placeholder ?? "Не выбран"}
                                    options={options}
                                    value={value}
                                    searchable
                                    onChange={(event) => setValue(name, event.currentTarget.value)}
                                    allowClearButton
                                /> :
                            type === 'file' ?
                                <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between"}}>
                                    {imagePreview && <Image id={'imagePreview'} src={imagePreview} size={100} />}
                                    <File
                                        before={<Icon24Document role="presentation" />}
                                        size="l"
                                        accept={'jpg,jpeg,png'}
                                        onChange={(e) => setValue(name, e.target.files[0])}
                                        mode="secondary"
                                    />
                                </div>
                                :
                            type === 'segmentControl' ?
                                <SegmentedControl
                                    value={value}
                                    defaultValue="none"
                                    onChange={(value) => setValue(name, value)}
                                    options={options}
                                /> :
                            type === 'textarea' ?
                                <Textarea
                                    value={value}
                                    onChange={(event) => setValue(name, event.currentTarget.value)}
                                    placeholder={placeholder}
                                /> :
                            type === 'switch' ?
                                <SimpleCell
                                    Component="label"
                                    after={
                                        <Switch
                                            checked={value}
                                            onChange={(event) => setValue(name, event.currentTarget.checked)}
                                        />} id={'is_active'}>
                                    {title}
                                </SimpleCell> :
                            type === 'chipsSelect' && options  ?
                                <ChipsSelect
                                    style={{width: '100%'}}
                                    options={options.map((option) => ({
                                        label: option[optionLabel],
                                        value: option[optionId],
                                        link: option[optionText] ? option[optionText][optionText] : null
                                    }))}
                                    value={value}
                                    onChange={(event) => setValue(event)}
                                    placeholder="Не выбраны"
                                    emptyText="Совсем ничего не найдено"
                                    selectedBehavior="hide"
                                    closeAfterSelect={false}
                                    renderOption={(props, {src, link, value}) => {
                                        return (
                                            <CustomSelectOption
                                                before={<Icon24LogoVkColor/>}
                                                description={link}
                                                {...props}
                                            />
                                        );
                                    }}
                                /> : null
                }
                {
                    helpText ?
                        <Div style={{padding: '10px 0'}}>
                            {
                                helpText.map(element => {
                                    return <small style={{color: 'gray', display: 'block', marginBottom: 5}}>
                                        - {element}
                                    </small>
                                })
                            }
                        </Div> : null
                }
            </FormItem> : null
    )
}