import * as React from "react";
import {useEffect} from "react";
import UserService from "../../services/user/UserService";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {Form} from "../../components/form/Form";
import CityService from "../../services/city/CityService";
import {CustomSpinner} from "../../components/vkUI/CustomSpinner";
import {FormField} from "../../components/form/FormField";
import UserAdminSettingsService from "../../services/user/UserAdminSettingsService";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const UserAdminSettingsModal = ({...props}) => {
    const cityService = new CityService(props.setPopout);
    const userAdminSettingsService = new UserAdminSettingsService(props.setPopout);

    const routeNavigator = useRouteNavigator();

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [cities, setCities] = React.useState([]);

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const fetchData = async () => {
        await userAdminSettingsService.show().then(res => {
            setFormData(userAdminSettingsService.model)
        })
        await cityService.all().then(res => {
            setCities(cityService.list)
        });
    }

    const onSubmit = async () => {
        await userAdminSettingsService.update(formData).then(res => {
            setLoading(false);
            routeNavigator.hideModal();
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <CustomModal id={'admin_user_settings_modal'} headerText={'Настройки'}>
            {
                !loading ?
                    <Form loading={loading} onSubmit={onSubmit}>
                        <FormField
                            title={'Город по умолчанию'}
                            type={'customSelect'}
                            placeholder="Введите название города"
                            searchable
                            options={cities}
                            name={'city_id'}
                            value={formData.city_id}
                            setValue={handler}
                        />
                        <FormField
                            title={'Название баллов сообщества'}
                            type={'input'}
                            placeholder="Название баллов сообщества"
                            name={'scores_name'}
                            value={formData.scores_name}
                            setValue={handler}
                        />
                        <FormField
                            title={'Telegram ID для уведомлений'}
                            type={'input'}
                            placeholder="Введите Telegram ID"
                            name={'chat_id'}
                            value={formData.chat_id}
                            setValue={handler}
                        />
                        <FormField
                            title={'Уведомления о действиях пользователей'}
                            type={'switch'}
                            name={'is_notify_about_user_actions'}
                            value={formData.is_notify_about_user_actions}
                            setValue={handler}
                        />
                        <FormField
                            title={'Ежедневные отчеты'}
                            type={'switch'}
                            name={'is_notify_everyday_report'}
                            value={formData.is_notify_everyday_report}
                            setValue={handler}
                        />
                    </Form> : <CustomSpinner />
            }
        </CustomModal>
    )
}
