import {Avatar, Group, RichCell} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect} from "react";
import {CustomSpinner} from "../../components/vkUI/CustomSpinner";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import VkUserService from "../../services/vk/VkUserService";

export const VkUserScoresModal = ({...props}) => {
    const vkUserService = new VkUserService(props.setPopout)

    const [loading, setLoading] = React.useState(false);
    const [userScores, setUserScores] = React.useState(false);

    const params = useParams();

    const fetchData = async () => {
        await vkUserService.getScores({vk_group_id: params.groupId, vk_user_id: params.vk_user_uuid}).then(res => {
            setUserScores(res.data.data)
        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <CustomModal
            id={'vk_user_scores_modal'}
            headerText={'История баланса'}
        >
            {
                !loading ?
                    <Group>
                        {
                            userScores.length > 0 ?
                                userScores.map((userScore, index) => (
                                    <RichCell
                                        key={index}
                                        subhead={userScore.comment}
                                        before={<Avatar size={48} src={userScore.vkGroup.photo} />}
                                        caption={userScore.created_at}
                                        after={<span style={{color: userScore.amount > 0 ? 'green' : 'tomato'}}>{userScore.amount}</span>}
                                    >
                                        {userScore.vkGroup.name}
                                    </RichCell>
                                )) : <p>Еще нет баллов</p>
                        }
                    </Group> : <CustomSpinner />
            }
        </CustomModal>
    )
}
